import React, { useState, useEffect } from "react";
import {
    List, Datagrid, Edit, Create, TextField, EditButton,
    required, FormDataConsumer, ReferenceInput, ArrayInput, SimpleFormIterator, NumberInput,
    TextInput, SelectInput, ImageInput, ImageField, ReferenceField,
    FunctionField, CheckboxGroupInput, TranslatableInputs, BooleanInput, SearchInput,
    Show, SimpleShowLayout, WithRecord, usePermissions, SelectArrayInput,
    ReferenceArrayInput, BooleanField, AutocompleteInput, CloneButton,
    TabbedForm, FormTab, useEditContext, DateInput, useDataProvider, SaveButton, Toolbar,
    Button
} from 'react-admin';

// hook form
import { useForm, useFormState } from 'react-hook-form';

// Firebase
import { db } from "../database/firebase";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from '../components/form/InputRow';
import SeatingPlanEditor from "../components/seating/SeatingPlanEditor";

// Libs
import { DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, trTR } from '@mui/x-data-grid-premium';
import { RichTextInput } from 'ra-input-rich-text';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';

// Utils
import { truncateString } from '../utils/misc';
import { EditableImage } from "../components/form/EditableImageField";

// UI
import { Box } from '@mui/material';

// Entity
const entity = "sessionEvents";

// Constants
const dressCodes = [{ id: 1, name: "Casual" }, { id: 2, name: "Come as you are" }, { id: 3, name: "Elegant" }, { id: 4, name: "Smart Casual" }, { id: 5, name: "No Dress Code" }];
const couplesRules = [{ id: 1, name: "No Rules" }, { id: 2, name: "Men have to be with women" }];


const filters = [
    <SearchInput source="name" />,
    <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }}>
        <SelectInput label="Venue Event" />
    </ReferenceInput>,
    <ReferenceInput source="city" reference="cities">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
    <ReferenceInput source="category" reference="categories">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
    <SelectInput source="isActive" choices={[{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }]} label="Active Filter" />,
    <SelectInput source="isVerified" choices={[{ id: true, name: 'Verified' }, { id: false, name: 'Awaiting' }]} label="Verified Filter" />,
    <ReferenceInput source="subcategory" reference="subcategories" sort={{ field: 'name.en', order: 'ASC' }}>
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
]

/**
 * 
 * @returns Event List
 */
export const SessionEventList = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    let filter = {
        hasSessions: !true
    };

    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) {
        filter.organisationCompanies = permissions.rid;
    }
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) {
        filter.venue = permissions.rid;
    }

    return (
        <List {...props} empty={<EmptyList entity={entity} />} sort={{ field: 'createdate', order: 'DESC' }} filters={filters} exporter={false} filter={filter} title="Session Events">
            <Datagrid bulkActionButtons={false}>
                <FunctionField source="name" sortable={true} sortBy="name" sortByOrder="ASC" render={r => truncateString(r.name, 30)} />

                <ReferenceField source="venue" reference="venues" link={false} sort={{ field: 'name', order: 'ASC' }}>
                    <FunctionField source="name" render={r => truncateString(r.name, 20)} />
                </ReferenceField>

                <ReferenceField source="category" reference="categories" link={false}>
                    <TextField source="name.en" label={"Category"} />
                </ReferenceField>

                <BooleanField source="isActive" label="Active" />

                <FunctionField source="isVerified" label="Verified" render={(r) => {
                    let bgColor = 'orange';
                    let status = 'Awaiting';
                    if (r.isVerified) { bgColor = 'green'; status = 'Verified' }

                    return (
                        <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3 }}>
                            <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                        </div>
                    )
                }} />

                <FunctionField source="id" label="Status" render={(r) => {
                    let bgColor = 'orange';
                    let status = 'Ongoing';

                    // take all sessions and see if any of the startdate's are in the future (format YYYY-MM-DD)
                    if (r.sessions) {
                        r.sessions.forEach(session => {
                            if (moment(session.startdate).isSameOrBefore(moment(), 'day')) {
                                bgColor = 'gray';
                                status = 'Past';
                            }
                        });
                    }

                    return (
                        <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3 }}>
                            <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                        </div>
                    )
                }} />

                <FunctionField source="id" label="Stock Status" render={r => <a href={`/#/sessionEvents/${r.id}/show`}>Sales Report</a>} />
                <FunctionField source="sales" label="Sales" render={r => <a href={`/#/sales?eventID=${r.id}`}>View Sales</a>} />
                <FunctionField source="tickets" label="Tickets" render={r => <a href={`/#/tickets?eventID=${r.id}`}>View Tickets</a>} />

                <EditButton />

            </Datagrid>
        </List>
    );
}

/**
 * 
 * @returns Event Edit
 */
export const SessionEventEdit = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    return (
        <Edit title="Edit" {...props} actions={false}>
            <SessionEventEditForm permissions={permissions} />
        </Edit>
    )
}
const SessionEventEditForm = ({ permissions }, ...props) => {
    const { record } = useEditContext();
    const { register, resetField } = useForm();
    const dataProvider = useDataProvider();

    return (
        <React.Fragment>

            <h2>Edit: {record.name} - Session Event</h2>
            {record.isSessionEvent && (
                <span style={{ fontSize: 12, color: 'white', backgroundColor: '#FAC898', padding: 5, marginBottom: 10 }}>
                    Warning! This is a session event of the main event:
                    <ReferenceField source="mainEvent" reference="events">
                        <TextField source="name" />
                    </ReferenceField>
                </span>
            )}

            <TabbedForm warnWhenUnsavedChanges toolbar={<CCreateToolbar />}>

                <FormTab label="Basic Details">
                    <h3>Basic Details</h3>

                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="name" label="Event Name" fullWidth validate={required()} className="autoCapitalize" />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }}>
                                <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} />
                            </ReferenceInput>
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <FormDataConsumer>
                                {({ formData, getSource, ...rest }) => {
                                    if (formData.venue && formData.venue.length > 0) {
                                        return (
                                            <ReferenceInput source="venue" reference="venues" filter={{ id: formData.venue }} validate={required()} label="Venue City">
                                                <SelectInput source="city" optionText="city" fullWidth disabled />
                                            </ReferenceInput>
                                        )
                                    }
                                }}
                            </FormDataConsumer>

                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={[]}>
                                <SelectArrayInput optionText={"name"} fullWidth disabled={permissions.crole && permissions.crole === "organisationCompanyManager"} defaultValue={[]} />
                            </ReferenceArrayInput>
                        </Box>
                    </Box>


                    <h3>Category</h3>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput source="category" reference="categories">
                                <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                            </ReferenceInput>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                                    return (
                                        <ReferenceArrayInput source="subcategories" reference="subcategories" label={"Sub Categories"} filter={filter} sort={{ field: 'name.en', order: 'ASC' }}>
                                            <SelectArrayInput optionText="name.en" fullWidth validate={required()} label={"Sub Categories"} />
                                        </ReferenceArrayInput>
                                    )
                                }}
                            </FormDataConsumer>
                        </Box>
                    </Box>

                    <h3>Payment Methods</h3>
                    <CheckboxGroupInput source="paymentMethods" choices={[
                        { id: 'cc', name: 'Credit Card' },
                        // { id: 'doorcash', name: 'Cash At Door' }
                    ]} validate={required()}
                        defaultValue={['cc']}
                    />
                </FormTab>

                <FormTab label="More Details">

                    <h3>Event Description</h3>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="description" label="Description" validate={required()} fullWidth />
                    </TranslatableInputs>

                    <h3>Event Rules</h3>

                    <h4>Things to know</h4>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="details" label="Event Rules" validate={required()} fullWidth />
                    </TranslatableInputs>

                    <h4>Rule Chips</h4>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Rules that are left empty won't appear on the front-end website.</span>
                    <TextInput source="rules.age" label="Age Rule" fullWidth defaultValue={null} />
                    <InputRow>
                        <SelectInput source="rules.dress" choices={dressCodes} fullWidth defaultValue={[]} />
                        <SelectInput source="rules.couples" choices={couplesRules} fullWidth defaultValue={[]} />
                    </InputRow>
                </FormTab>

                <FormTab label="Visuals">
                    <h3>Visual / Poster</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images that are of size 500 x 500 should be uploaded.</span>

                    {/* <ImageInput source="banner" label="Event Visual" accept="image/*" defaultValue={null} >
                        <ImageField source="src" title="title" />
                    </ImageInput> */}

                    <EditableImage record={record} source="banner" height={500} width={500} {...props} label="Image label" />

                    <h3>Event Video</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
                    <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />

                    <h3>Venue Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        If there is a specific layout for this event. If not default layout for the venue will be used.
                    </span>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="layout" label=" " accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>



                </FormTab>

                <FormTab label="Products/Tickets">
                    <ArrayInput source="tickets" defaultValue={[]}>
                        <SimpleFormIterator disableReordering className="sessionEventTicketsArray">
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return productForm(getSource, false, scopedFormData);
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Layout">
                    <h3>Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        Venue should be selected first to see the available layouts.
                    </span>

                    {/* A button to "reset" the layout. This will just delete the layoutJSON field of the record and reload the page. */}
                    <Button variant="contained" color="primary" style={{ marginBottom: 20 }} onClick={async () => {
                        if (window.confirm("Are you sure you want to reset the layout?")) {
                            await dataProvider.update('sessionEvents', { id: record.id, data: { layout: null, layoutJSON: null } })

                            // reload the page
                            window.location.reload();
                        }
                    }} label="Reset Layout" />


                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.venue && formData.venue.length > 0 ? { venue: formData.venue, layoutType: 'seating' } : { layoutType: 'seating' };
                            let toReturn = null;

                            if (formData.venue && !formData.layout) {
                                toReturn = (
                                    <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}>
                                        <SelectInput optionText={"name"} fullWidth />
                                    </ReferenceInput>
                                )
                            } else if (formData.venue && formData.layout && formData.layoutJSON) {
                                toReturn = (
                                    <React.Fragment>
                                        <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}>
                                            <SelectInput optionText={"name"} fullWidth
                                            // onChange={() => {
                                            // // warn the user that this will delete formData.layoutJSON and do so if they confirm
                                            // if (window.confirm("Changing the layout will delete the current layout data. Are you sure you want to continue?")) {
                                            //     resetField('name');
                                            // }
                                            // }} 
                                            />
                                        </ReferenceInput>
                                        <SeatingPlanEditor fullLayout record={formData.layoutJSON} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} layoutType="seating" />
                                    </React.Fragment>
                                );
                            } else if (formData.venue && formData.layout && !formData.layoutJSON) {

                                toReturn = (
                                    <React.Fragment>
                                        <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}>
                                            <SelectInput optionText={"name"} fullWidth />
                                        </ReferenceInput>
                                        <SeatingPlanEditor record={formData.layout} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} layoutType="seating" />
                                    </React.Fragment>
                                );
                            }
                            return toReturn;
                        }}
                    </FormDataConsumer>

                </FormTab>

                <FormTab label="Sessions">
                    <ArrayInput source="sessions" defaultValue={[]} label="Sessions" validate={required()}>
                        <SimpleFormIterator
                            disableReordering
                            // disableRemove
                            className="sessionEventSessionsArray"
                            addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Date" />}

                        >
                            <DateInput source="startdate" fullWidth validate={required()} label="Date" />

                            <ArrayInput source="times" defaultValue={[]} label="Times"
                            >
                                <SimpleFormIterator
                                    disableReordering
                                    // disableRemove
                                    inline
                                    addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Time" />}
                                >
                                    <FormDataConsumer>
                                        {({ formData, scopedFormData, getSource, ...rest }) => (
                                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} style={{ alignItems: 'center' }}>
                                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                    <TextInput
                                                        source={getSource('time')} fullWidth validate={required()}
                                                        label="Saat"
                                                        type="time"
                                                        InputProps={{ inputProps: { step: 300 } }}
                                                    />
                                                </Box>
                                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                    <BooleanInput source={getSource('isActive')} label="Active" defaultValue={true} defaultChecked={true} />
                                                </Box>
                                            </Box>
                                        )}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>

                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Fees">
                    <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)" helperText="Service fee to cover extra operational cost" defaultValue={0} disabled={(permissions.crole && permissions.crole !== "admin")} />
                    <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee" min={0} fullWidth label="Event Commission Fee (%)" defaultValue={10} disabled={(permissions.crole && permissions.crole !== "admin")} />
                    <BooleanInput source="isCommissionExtra" defaultValue={false} label="Is commission extra (added to ticket prices)?" helperText="Please note that the prices for the event will change if this is turned on." />
                </FormTab>

                <FormTab label="Settings">
                    <h3>Toggles</h3>
                    <BooleanInput source="isActive" label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." />
                    <BooleanInput source="isVerified" label="Verified Event?" helperText="Is event verified by the admin?" disabled={permissions.crole && permissions.crole !== "admin"} />
                    <div style={{ visibility: 'hidden' }}>
                        <BooleanInput source="isActive" label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." />
                    </div>
                    <h3>Extra Fields</h3>
                    <FunctionField source="id" label="Preview Link" render={(r) => {
                        return (
                            <div style={{ fontSize: 12 }}>
                                Event Preview Link: &nbsp;
                                <a href={"https://www.gisekibris.com/etkinlikler/".concat(r.id).toString().concat('?mode=preview')} target="_blank">
                                    {
                                        r.name
                                    }
                                </a>
                            </div>
                        )
                    }} />
                </FormTab>

            </TabbedForm>

        </React.Fragment>

    );


}


export const SessionEventCreate = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    return (
        <Create title="Create New Session Event" {...props} redirect={"list"}
            transform={(data) => {
                // console.log(data)
            }}>
            <SessionEventCreateForm permissions={permissions} />
        </Create>
    );
}

const CCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            alwaysEnable
            transform={data => {
                const seatingPlan = localStorage.getItem('seatingPlan');
                if (seatingPlan) localStorage.removeItem('seatingPlan');

                // also full uppercase the "name"
                if (data.name && data.name.length > 0) data.name = data.name.toUpperCase();

                // also uppercase the "name" of the tickets
                if (data.tickets && data.tickets.length > 0) {
                    data.tickets.forEach((ticket, index) => {
                        if (ticket.title && ticket.title.en && ticket.title.en.length > 0) ticket.title.en = ticket.title.en.toUpperCase();
                        if (ticket.title && ticket.title.tr && ticket.title.tr.length > 0) ticket.title.tr = ticket.title.tr.toUpperCase();
                    });
                }

                let toReturn = { ...data, notify: true };

                if (seatingPlan) {
                    toReturn = { ...toReturn, layoutJSON: seatingPlan };
                }

                return toReturn;
            }}
            type="button"
        />
    </Toolbar>
);

const SessionEventCreateForm = ({ permissions }, ...props) => {

    const dataProvider = useDataProvider();

    // fetch system parameters and get "system" doc to get the default values for the fees

    const [systemParams, setSystemParams] = React.useState(null);

    React.useEffect(() => {
        dataProvider.getOne('systemParameters', { id: 'system' })
            .then(res => {
                setSystemParams(res.data);
            })
    }, []);

    if (!systemParams) return "Loading...";

    return (
        <React.Fragment>
            <h2>Create New Session Event</h2>
            <TabbedForm warnWhenUnsavedChanges id="eventForm" toolbar={<CCreateToolbar />}>
                <FormTab label="Basic Details">
                    <h3>Basic Details</h3>
                    <TextInput source="name" label="Event Name" fullWidth validate={required()} className="autoCapitalize" />


                    <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                        <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()} />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                            if (formData.venue && formData.venue.length > 0) {
                                return (
                                    <ReferenceInput source="venue" reference="venues" filter={{ id: formData.venue }} validate={required()} label="Venue City">
                                        <SelectInput source="city" optionText="city" fullWidth disabled />
                                    </ReferenceInput>
                                )
                            }
                        }}
                    </FormDataConsumer>

                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={[]}
                        sort={{
                            field: 'name',
                            order: 'ASC'
                        }} perPage={50}
                    >
                        <SelectArrayInput optionText={"name"} fullWidth disabled={permissions.crole && permissions.crole === "organisationCompanyManager"} value={(permissions.crole && permissions.crole === "organisationCompanyManager") && [permissions.rid]} defaultValue={(permissions.crole && permissions.crole === "organisationCompanyManager") ? [permissions.rid] : []} />
                    </ReferenceArrayInput>

                    <h3>Category</h3>
                    <ReferenceInput source="category" reference="categories" sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                            return (
                                <ReferenceArrayInput source="subcategories" reference="subcategories" label={"Sub Categories"} filter={filter} sort={{ field: 'name.en', order: 'ASC' }}>
                                    <SelectArrayInput optionText="name.en" fullWidth validate={required()} label={"Sub Categories"} />
                                </ReferenceArrayInput>
                            )
                        }}
                    </FormDataConsumer>
                    <h3>Payment Methods</h3>
                    <CheckboxGroupInput source="paymentMethods" choices={[
                        { id: 'cc', name: 'Credit Card' },
                    ]} validate={required()}
                        defaultValue={['cc']}
                    />
                </FormTab>
                <FormTab label="More Details">
                    <h3>Event Description <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h3>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="description" label="Description" validate={required()} fullWidth />
                    </TranslatableInputs>

                    <h3>Event Rules</h3>

                    <h4>Things to know <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h4>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="details" label="Event Rules" validate={required()} fullWidth />
                    </TranslatableInputs>

                    <h4>Rule Chips</h4>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Rules that are left empty won't appear on the front-end website.</span>
                    <TextInput source="rules.age" label="Age Rule" fullWidth defaultValue={null} />
                    <InputRow>
                        <SelectInput source="rules.dress" choices={dressCodes} fullWidth defaultValue={[]} />
                        <SelectInput source="rules.couples" choices={couplesRules} fullWidth defaultValue={[]} />
                    </InputRow>
                </FormTab>
                <FormTab label="Visuals">
                    <h3>Visual / Poster</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images that are of size 500 x 500 should be uploaded.</span>
                    <EditableImage source="banner" {...props} />
                    <h3>Event Video</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
                    <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />

                    <h3>Venue Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        If there is a specific layout for this event. If not default layout for the venue will be used.
                    </span>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="layout" label=" " accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Products/Tickets">
                    <ArrayInput source="tickets" defaultValue={[]}>
                        <SimpleFormIterator disableReordering className="sessionEventTicketsArray">
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return productForm(getSource, false, scopedFormData);
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Layout">
                    <h3>Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        Venue should be selected first to see the available layouts.
                    </span>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.venue && formData.venue.length > 0 ? { venue: formData.venue, layoutType: 'seating' } : { layoutType: 'seating' };
                            let toReturn = null;

                            if (formData.venue && !formData.layout) {
                                toReturn = (
                                    <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}>
                                        <SelectInput optionText={"name"} fullWidth />
                                    </ReferenceInput>
                                )
                            } else if (formData.venue && formData.layout) {
                                toReturn = (
                                    <React.Fragment>
                                        <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}>
                                            <SelectInput optionText={"name"} fullWidth />
                                        </ReferenceInput>
                                        <SeatingPlanEditor record={formData.layout} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} layoutType="seating" />
                                    </React.Fragment>
                                );
                            }

                            return toReturn;
                        }}
                    </FormDataConsumer>

                </FormTab>
                <FormTab label="Sessions">
                    <ArrayInput source="sessions" defaultValue={[]} label="Sessions" validate={required()}>
                        <SimpleFormIterator disableReordering
                            className="sessionEventSessionsArray"
                            addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Date" />}

                        >
                            <DateInput source="startdate" fullWidth validate={required()} label="Date" />

                            <ArrayInput source="times" defaultValue={[]} label="Times"
                            >
                                <SimpleFormIterator
                                    disableReordering
                                    inline
                                    addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Time" />}
                                >
                                    <FormDataConsumer>
                                        {({ formData, scopedFormData, getSource, ...rest }) => (
                                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} style={{ alignItems: 'center' }}>
                                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                    <TextInput
                                                        source={getSource('time')} fullWidth validate={required()}
                                                        label="Time"
                                                        type="time"
                                                        InputProps={{ inputProps: { step: 300 } }}
                                                    />
                                                </Box>
                                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                    <BooleanInput source={getSource('isActive')} label="Active" defaultValue={true} defaultChecked={true} />
                                                </Box>
                                            </Box>
                                        )}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>

                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Fees">
                    {
                        systemParams && systemParams.variables ? (
                            <React.Fragment>
                                <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)"
                                    helperText="Service fee to cover extra operational cost"
                                    defaultValue={systemParams.variables.defaultServiceFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee"
                                    min={0} fullWidth label="Event Commission Fee (%)"
                                    defaultValue={systemParams.variables.defaultCommissionFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <BooleanInput source="isCommissionExtra" defaultValue={false} label={`Is ${systemParams.variables.defaultCommissionFee}% commission extra (added to ticket prices)?`} helperText="Please note that the prices for the event will change if this is turned on." />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)" helperText="Service fee to cover extra operational cost" defaultValue={0} />
                                <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee" min={0} fullWidth label="Event Commission Fee (%)" defaultValue={10} />
                                <BooleanInput source="isCommissionExtra" defaultValue={false} label="Is 10% commission extra (added to ticket prices)?" helperText="Please note that the prices for the event will change if this is turned on." />
                            </React.Fragment>
                        )
                    }
                </FormTab>
                <FormTab label="Settings">
                    <BooleanInput source="isActive" value={true} checked={true} label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." hidden={true} disabled={true} />
                    <BooleanInput source="isVerified" value={(permissions.crole && permissions.crole === "admin")} checked={(permissions.crole && permissions.crole === "admin")} label="Verified Event?" helperText="Is event verified by the admin?" hidden={true} disabled={true} />
                </FormTab>
            </TabbedForm>
        </React.Fragment>
    )
}


export const SessionEventShow = () => {
    return (
        <Show title={"Event Stock"}>
            <SimpleShowLayout>
                <FunctionField render={(r) => {
                    return (
                        <div style={{ fontSize: 25 }}>
                            {`${r.name} - Sessions Report`}
                        </div>
                    )
                }} label="Stock Information" />

                <WithRecord render={record => {
                    let ret = [];
                    if (record && record.sessions) {

                        // Loop through sessions and print startdate
                        // then make another loop for the times and print the time

                        record.sessions.map((session, index) => {
                            ret.push(
                                <div key={index} style={{ marginTop: 20 }}>
                                    <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        {moment(session.startdate).format("LL")}
                                    </div>
                                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                                        {session.times.map((time, index) => {
                                            return (
                                                <div key={index} style={{ marginTop: 10 }}>
                                                    <SessionTimeAndAvailability sessionEvent={record} sessionEventID={record.id} date={session.startdate} time={time.time} sessionEpoch={session} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })

                        return ret;
                    }
                }} />
            </SimpleShowLayout>
        </Show>
    )
}

const SHOW_COLUMNS = [
    { field: 'id', headerName: 'Product ID', width: 100, filterable: false, valueGetter: (params) => params.row.id },
    { field: 'name', headerName: 'Product Name', width: 100, filterable: false, valueGetter: (params) => params.row.name },
    { field: 'count', headerName: 'Sold', width: 100, filterable: false, valueGetter: (params) => params.row.count },
    { field: 'total', headerName: 'Total', width: 100, filterable: false, valueGetter: (params) => params.row.total, valueFormatter: (params) => params.value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) }
];

const SessionTimeAndAvailability = ({ sessionEvent, sessionEventID, date, time }) => {
    const dataProvider = useDataProvider();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {

        if (!date || !time || !sessionEvent) return setError(true);

        const fetchData = async () => {

            // const result = await dataProvider.getOne('seatSelections', {
            //         id: sessionEventID.concat(moment(date, 'YYYY-MM-DD').set({ hour: moment(time, 'HH:mm').get('hour'), minute: moment(time, 'HH:mm').get('minute') }).format('X')),
            //     });

            const collectionRef = collection(db, 'sales');
            let q = query(collectionRef, orderBy('createdate', 'desc'),
                where('event.id', '==', sessionEvent.id),
                // where('status', '==', 1) // requires index, so we will do this on the client side
            );
            const dataSnapshot = await getDocs(q);

            // loop through docs and add id
            let docs = [];
            dataSnapshot.forEach(doc => {
                // Eliminate the ones with status != 1
                const sessionEpoch = moment(date, 'YYYY-MM-DD').set({ hour: moment(time, 'HH:mm').get('hour'), minute: moment(time, 'HH:mm').get('minute') }).format('X');
                if (doc.data().status === 1 && doc.data().event.session == sessionEpoch) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });

            setData(docs);
            setLoading(false);
        };

        fetchData();

    }, [date, time, dataProvider]);

    if (loading) return <div>Loading...</div>;

    if (error) return <div>Error</div>;

    let tableData = {};

    if (data) {

        Object.values(data).map(sale => {
            if (typeof sale === 'object' && sale.seats) {
                sale.seats.forEach(seat => {

                    // tableData should have sessionEpoch first which is in sale.event.session
                    // then it should have productID which is in seats[].productID

                    // if tableData doesn't have sessionEpoch, create it

                    if (!tableData[sale.event.session]) {
                        tableData[sale.event.session] = {};
                    }

                    // Then we can increment the count in productID
                    if (!tableData[sale.event.session][seat.productID]) {
                        // find name of the productID and add it to the tableData
                        let product = sessionEvent.tickets.find(r => r.id === seat.productID);
                        tableData[sale.event.session][seat.productID] = {
                            name: product.title.en,
                            count: 1,
                            total: product.price
                        };
                    } else {
                        let product = sessionEvent.tickets.find(r => r.id === seat.productID);
                        tableData[sale.event.session][seat.productID].count = tableData[sale.event.session][seat.productID].count + 1;
                        tableData[sale.event.session][seat.productID].total = tableData[sale.event.session][seat.productID].total + product.price;
                    }

                });
            }
        });
    }

    // console.log(tableData);

    // convert tableData into data grid format
    let tableDataGrid = [];
    Object.keys(tableData).map(sessionEpoch => {
        Object.keys(tableData[sessionEpoch]).map(productID => {
            tableDataGrid.push({
                id: productID,
                name: tableData[sessionEpoch][productID].name,
                count: tableData[sessionEpoch][productID].count,
                total: tableData[sessionEpoch][productID].total,
            });
        });
    });


    // order by count desc
    tableDataGrid.sort((a, b) => {
        return b.count - a.count;
    });


    let totalRevenue = tableDataGrid.reduce((a, b) => a + b.total, 0);
    let totalSale = data.reduce((a, b) => a + b.paymentTotal, 0);

    return (
        <div style={{ borderBottom: '2px solid #AAA', paddingBottom: 50, paddingTop: 50 }}>
            <div style={{ marginBottom: 30 }}>
                <span style={{ fontWeight: 'bold' }}>Time: </span>
                <span>{moment(time, 'HH:mm').format('HH:mm')}</span>
            </div>

            <DataGridPremium
                rows={tableDataGrid}
                density="compact"
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    pagination: { paginationModel: { pageSize: 100 } },
                }}
                hideFooter={true}
                style={{ flex: 1, width: '100%' }}
                getRowId={(row) => row.id}
                columns={SHOW_COLUMNS}
            />

            {/* Add a custom footer to display the totals */}
            <div style={{ marginTop: 20 }}>
                <div>
                    <b>Total Sold: </b> {data.length.toString().concat(' Sales')} ({tableDataGrid.reduce((a, b) => a + b.count, 0).toString().concat(' Seats/Tickets')})
                </div>
                <div>
                    {/* total revenue from product price */}
                    <b>Total Revenue: </b> {totalRevenue.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                </div>
                {
                    sessionEvent.isCommissionExtra && (
                        <div>
                            <b>Commission: </b> {sessionEvent.commissionFee}% / {(totalRevenue * sessionEvent.commissionFee / 100).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>
                    )
                }
                <div>
                    {/* total sale with commission */}
                    <b>Total Sale: </b> {totalSale.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                </div>
                {
                    sessionEvent.isCommissionExtra && (
                        <div>
                            {/* does total with commission match totalSale? */}
                            <b>Check: </b> {totalSale == (totalRevenue * (sessionEvent.commissionFee + 100) / 100) ? <span style={{ color: 'green' }}>Yes</span> : 'No'}
                        </div>
                    )
                }
            </div>

        </div>
    )
};


// Show Component Exporter
// Exports Sales Data of the event to CSV
const showExporter = (record) => {
    let data = [];
    const tickets = record.tickets;

    let totalTotalSale = 0;

    tickets.deal.map(r => {
        data.push({
            "Category": "Promotion",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        });

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.extras.map(r => {
        data.push({
            "Category": "Extras",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        })

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.general.map(r => {
        data.push({
            "Category": "General",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        })

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.guestlist.map(r => {
        data.push({
            "Category": "Reservation",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        });

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.vip.map(r => {
        data.push({
            "Category": "VIP",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        });

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    if (tickets.invitation) {
        tickets.invitation.map(r => {
            data.push({
                "Category": "Invitation",
                "Title": r.title.tr,
                "Price": r.price,
                "Stock": r.stock,
                "Sold": r.sold,
                "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                    parseInt(r.stock) - parseInt(r.sold)
                ) : 'N/A',
                "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
            });

            if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
            return true;
        });
    }

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        arrayOfArrayCsv.unshift([`${record.name} - ${moment(record.startdate).format("LL")}`]);
        arrayOfArrayCsv.push(['', '', '', '', '', '', `${totalTotalSale.toString().concat(' TL')}`]);

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-stock-${slugify(record.name)}-export--${moment().format('LL-LT')}.xlsx`);
    });
}

// Tools
const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
const CustomCloneButton = ({ record }) => {
    return record ? (
        <CloneButton to={{
            pathname: '/events/create',
            search: `?source=${JSON.stringify(omitUniqueFields(record))}`
        }} basePath="/events" label="Clone" />
    ) : null;
};
const omitUniqueFields = (record) => {
    return {
        name: record.name,
        category: record.category,
        subcategories: record.subcategories ?? [],
        // description: record.description,
        // details: record.details,
        organisationCompanies: record.organisationCompanies ?? [],
        paymentMethods: record.paymentMethods ?? [],
        rules: record.rules ?? {},
        servicefee: record.servicefee ?? 0,
        // tickets: record.tickets,
        venue: record.venue,
        videoURL: record.videoURL ?? ''
    }
};


const productForm = (getSource, showSold, scopedFormData) => {
    return (
        <React.Fragment>
            {/* Auto generate ticket ID on add */}
            <TextInput source={getSource("id")} label={"Ticket ID"} validate={required()} fullWidth disabled defaultValue={genereateTicketID()} />

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TranslatableInputs locales={['tr', 'en']} className="translatableInputST" defaultLocale="tr">
                        <TextInput source={getSource("title")} label={"Title"} validate={required()} fullWidth autoComplete="off" />
                    </TranslatableInputs>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TranslatableInputs locales={['tr', 'en']} defaultLocale="tr">
                        <TextInput source={getSource("description")} label={"Description"} validate={required()} fullWidth multiline rows={3} />
                    </TranslatableInputs>
                </Box>
            </Box>
            <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("price")} type="number" label={"Price"} validate={required()} fullWidth />
            <BooleanInput source={getSource('isPromotionAvailable')} label={"Promotion Available?"} fullWidth />


            {scopedFormData && scopedFormData.isPromotionAvailable && (
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TranslatableInputs locales={['tr', 'en']} defaultLocale="tr">
                            <TextInput source={getSource("promotion.label")} label={"Promotion Label"} fullWidth />
                        </TranslatableInputs>
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("promotion.price")} type="number" label={"Promotion Price"} fullWidth />
                    </Box>
                </Box>
            )}

            {/* {showSold && <NumberInput source={getSource("sold")} type="number" label={"Total Sold"} disabled fullWidth />} */}
        </React.Fragment>
    )
}

const genereateTicketID = () => {
    // Capital letters and numbers, length 8
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 8).toUpperCase();
}