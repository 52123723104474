import React from 'react';

// UI
import { Button } from 'react-admin';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { EditOutlined as EditIcon, FormatColorFill, PhotoSizeSelectActual, FormatColorText } from '@mui/icons-material';
import CopyAllOutlined from '@mui/icons-material/CopyAllOutlined';

// import FormControl, InputLabel, Select, MenuItem
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';

// Konva
import Konva from 'konva';
import { Stage, Layer } from 'react-konva';

// Constants
// const width = window.innerWidth / 1.5;
// const height = window.innerHeight / 1;
const width = 2000;
const height = 2000;
const blockSnapSize = 35;
const standardEventTicketGroups = ['deal', 'general', 'guestlist', 'vip', 'extras', 'invitation'];

class SeatingPlanCanvas extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectedShapes: [],
            selectedShape: null,
            selectedTicket: null,
            selectedAreaProducts: [],
            lastY: 0,
            lastRow: 'A',
            layoutLoaded: false,
        }

        this.editMode = false;
    }

    componentDidMount() {
        const { record, isAssignMode, fullLayout, isViewOnly } = this.props;

        let _this = this;

        if (isViewOnly && record) {
            console.log('CDM1');
            this.loadLayoutLocal(record.layout);
            setTimeout(() => {
                this.addGridLayer();
            }, 250);
        } else {
            if (isAssignMode && record) {
                console.log('CDM2');
                if (fullLayout) {
                    setTimeout(() => {
                        console.log('CDM2.5');
                        this.loadLayoutLocal(record);
                        this.addSelectionTool();

                        _this.setState({ selectedShapes: [] });

                    }, 500);
                } else {
                    console.log('CDM3');
                    // fetch layout by id from record.layout
                    (
                        async () => {
                            await this.loadLayout(record);
                            setTimeout(() => {
                                this.addGridLayer();
                                this.addSelectionTool();
                                _this.setState({ selectedShapes: [] })
                            }, 250);
                        }
                    )()
                }
            } else {

                console.log('CDM4');
                // New create layout screen probably.

                this.addGridLayer();
                this.addSelectionTool();
                this.addShadowRect();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { record, isAssignMode, fullLayout, layoutType, layoutJSON } = this.props;

        if (isAssignMode && record && record !== prevProps.record) {
            if (fullLayout) {
                console.log('CDU 1')
                this.loadLayoutLocal(record);
            }
            else {
                console.log('CDU 2')
                this.loadLayout(record);
            }
        }

        if (!isAssignMode && layoutType && layoutType !== prevProps.layoutType) {
            console.log('CDU 3')
            // delete all shapes
            this.layer.find('Group').forEach(group => {
                group.destroy();
            });
        }

        if (isAssignMode && layoutJSON && layoutJSON !== prevProps.layoutJSON) {
            console.log('CDU 4')
            // console.log('changed');
            // // delete all shapes
            // this.layer.find('Group').forEach(group => {
            //     group.destroy();
            // });

            // this.loadLayout(record);
        }
    }

    loadLayout = async (layoutId) => {
        const { dataProvider } = this.props;
        const { data } = await dataProvider.getOne('layouts', { id: layoutId });

        if (data && data.layout) {
            let stageJSON = JSON.parse(data.layout);
            this.stage = Konva.Node.create(stageJSON, 'container');

            this.stage.width(width);
            this.stage.height(height);

            // add layer ref
            this.layer = this.stage.find('Layer')[0];

            // If we are loading a previously saved layout, we need to disable dragging on the seats
            this.layer.find('Group').forEach(group => {
                group.draggable(false);
            });

            if (this.layer.find('Image').length > 0) {
                // get url from image attr source
                let url = this.layer.find('Image')[0].attrs.source;
                if (url && url !== '') {
                    this.addBackgroundImageForDisplay(url);
                }
            }

            this.stage.draw();

            this.setState({ layoutLoaded: true });
            return true;
        }

    }

    loadLayoutLocal = (layout) => {
        if (layout && JSON.parse(layout)) {
            let stageJSON = JSON.parse(layout);
            this.stage = Konva.Node.create(stageJSON, 'container');

            this.stage.width(width);
            this.stage.height(height);

            // add layer ref
            this.layer = this.stage.find('Layer')[0];

            // If we are loading a previously saved layout, we need to disable dragging on the seats
            this.layer.find('Group').forEach(group => {
                group.draggable(false);
            });

            // If there is a background image, find it and add it to the stage
            if (this.layer.find('Image').length > 0) {
                // get url from image attr source
                let url = this.layer.find('Image')[0].attrs.source;
                if (url && url !== '') {
                    this.addBackgroundImageForDisplay(url);
                }
            }

            this.stage.draw();

            this.setState({ layoutLoaded: true });
            return true;
        }
    }

    addGridLayer = () => {
        var gridLayer = new Konva.Layer();
        var padding = blockSnapSize;
        for (var i = 0; i < width / padding; i++) {
            gridLayer.add(new Konva.Line({
                points: [Math.round(i * padding) + 0.5, 0, Math.round(i * padding) + 0.5, height],
                stroke: '#ddd',
                strokeWidth: 1,
            }));
        }

        gridLayer.add(new Konva.Line({ points: [0, 0, 10, 10] }));
        for (var j = 0; j < height / padding; j++) {
            gridLayer.add(new Konva.Line({
                points: [0, Math.round(j * padding), width, Math.round(j * padding)],
                stroke: '#ddd',
                strokeWidth: 0.5,
            }));
        }

        if (this.stage) {
            this.gridLayer = gridLayer;
            this.stage.add(gridLayer);
        }
    }

    addShadowRect = () => {
        var shadowRectangle = new Konva.Rect({
            x: 0,
            y: 0,
            width: blockSnapSize,
            height: blockSnapSize,
            fill: '#FF7B17',
            opacity: 0.6,
            stroke: '#CF6412',
            strokeWidth: 3,
            dash: [20, 2]
        });

        shadowRectangle.hide();

        if (this.layer) {
            this.layer.add(shadowRectangle);
            this.shadowRectangle = shadowRectangle;
        }
    }

    addSelectionTool = () => {
        const { isCreateMode, isAssignMode } = this.props;
        
        let selectionRect;
        let stage = this.stage;
        let layer = this.layer;

        if(!stage || !layer) return;

        stage.on('mousedown', (e) => {

            // remove previous stuck selection rect
            if (selectionRect) {
                // console.log('removing previous selection');
                selectionRect.destroy();
                selectionRect = null;
            }

            // remove previous colored shapes
            // if they don't have the attr bgColor
            const selectedSeats = this.state.selectedShapes;
            console.log('selectedSeats in mousedown', selectedSeats);
            selectedSeats.forEach(seat => {
                
                // t,
                console.log(seat);

                if (seat && seat.attrs && (seat.attrs.ticket || seat.attrs.tickets)) {
                 
                    if (seat.attrs.ticket) {

                        console.log("There is ticket attr for the seat", seat.attrs.ticket);
                        seat.children[0].fill(stringToColour(seat.attrs.ticket));

                        if (seat.children[0].attrs.prevBGColor && seat.children[0].attrs.prevBGColor.length !== 0 && !isAssignMode) {
                            console.log("There is prevBGColor for the seat and we are NOT in the assignMode", seat.children[0].attrs.prevBGColor);

                            seat.children[0].fill(seat.children[0].attrs.prevBGColor);
                            seat.children[0].setAttr('prevBGColor', null);
                        }
                        else {
                            console.log("There is no prevBGColor for the seat, just fill the TEXT with white, because there is a ticket set so we need white text.");
                            seat.children[1].fill('white');
                        }
                    }


                    if (seat.attrs.tickets) {
                        console.log("There is multi tickets attr for the seat");
                        seat.children[0].fill("green");
                    }

                } else {
                    console.log("No ticket or tickets attr for the seat");
                    // console.log(seat.children[0].attrs.prevBGColor);

                    if (seat.children[0].attrs.prevBGColor && seat.children[0].attrs.prevBGColor.length !== 0) {
                        
                        console.log("There is prevBGColor for the seat", seat.children[0].attrs.prevBGColor);

                        if (seat.children[0].attrs.prevBGColor === 'white' || seat.children[0].attrs.prevBGColor === '#FFF' || seat.children[0].attrs.prevBGColor === 'transparent' || isCreateMode) {
                            console.log("Prev BG color is white or #FFF or we are in createMode, so we are going to fill it with that.");
                            seat.children[0].fill(seat.children[0].attrs.prevBGColor);

                            // CONSIDER DOING THIS FOR WHITE AND FFF TOO????
                            if(seat.children[0].attrs.prevBGColor === 'transparent') {
                                console.log("YES IT IS");
                                // 
                                seat.children[0].setAttr('prevBGColor', 'transparent');
                            } else {
                                console.log("NO ITS NOT");
                                seat.children[0].setAttr('prevBGColor', null);
                            }

                        } else {
                            console.log("Prev BG color is not white or #FFF:", seat.children[0].attrs.prevBGColor);
                            // seat.children[0].fill('#FFF');
                        }
                    }
                    else {
                        console.log("There is no prevBGColor for the seat, just fill white");
                        seat.children[0].fill('#FFF');
                    }
                }

                if (seat.attrs.bgColor) {
                    console.log("There is bgColor attr for the seat, fill it with that", seat.attrs.bgColor);
                    seat.children[0].fill(seat.attrs.bgColor);
                }
            });


            console.log('ended mousedown selectedSeats manipulation, now creating a new selection rect');

            // create a new selection rect
            selectionRect = new Konva.Rect({
                x: e.evt.offsetX,
                y: e.evt.offsetY,
                width: 0,
                height: 0,
                fill: 'rgba(0,0,255,0.5)',
                stroke: 'blue',
                strokeWidth: 1,
                listening: false
            });

            // add the selection rect to the layer
            layer.add(selectionRect);
        });

        stage.on('mousemove', (e) => {
            if (selectionRect) {

                // Update the size of the selection rect
                selectionRect.width(e.evt.offsetX - selectionRect.x());
                selectionRect.height(e.evt.offsetY - selectionRect.y());

                layer.batchDraw();

            }
        });

        stage.on('mouseup', (e) => {
            if (selectionRect) {
                const groups = this.layer.getChildren(node => node.getClassName() === 'Group');
                const seats = groups.filter(rect => Konva.Util.haveIntersection(rect.getClientRect(), selectionRect.getClientRect()));

                // color the selected seats
                seats.forEach(seat => {
                    const prevBG = seat.children[0].fill();
                    seat.children[0].setAttr('prevBGColor', prevBG)
                    setTimeout(() => {
                        seat.children[0].fill('#FF7B17')
                    }, 150);
                });

                // save to state
                this.setState({ selectedShapes: seats });

                // remove the selection rect from the layer
                selectionRect.destroy();
                layer.batchDraw();

                localStorage.setItem('seatingPlan', this.stage.toJSON());
            }
        });
    }

    addShape = (event) => {
        event.preventDefault();

        let lastX = 0;
        let lastY = 0;

        const lastGroup = this.layer.find("Group").pop();

        if (lastGroup) {
            lastX = lastGroup.x();
            lastY = lastGroup.y();
        }

        const label = new Konva.Text({
            text: '1',
            fontSize: 12,
            // padding: 5,
            x: 7,
            y: 7,
            fontFamily: 'Arial',
            fill: 'black'
        });

        if (lastGroup) {
            const labelNum = parseInt(lastGroup.children[1].attrs.text) || 0;
            label.text(labelNum + 1);
        }

        const shape = new Konva.Rect({
            name: 'drawn',
            width: blockSnapSize,
            height: blockSnapSize,
            scaleX: 0.9,
            scaleY: 0.9,
            x: 2,
            y: 2,
            fill: '#FFF',
            stroke: 'black',
            strokeWidth: 1.5,
            stroke: '#000',
            // draggable: true
        });

        const seatGroup = new Konva.Group({
            x: lastX + blockSnapSize,
            y: lastY,
            draggable: true
        });

        seatGroup.add(shape);
        seatGroup.add(label);

        let shadowRectangle = this.shadowRectangle;
        let stage = this.stage;

        seatGroup.on('dragstart', (e) => {
            shadowRectangle.show();
            shadowRectangle.moveToTop();
            seatGroup.moveToTop();
        });
        seatGroup.on('dragmove', (e) => {

            shadowRectangle.position({
                x: Math.round(seatGroup.x() / blockSnapSize) * blockSnapSize,
                y: Math.round(seatGroup.y() / blockSnapSize) * blockSnapSize
            });
            stage.batchDraw();

            // Can we scroll the container when it hits the edge?
            const scrollContainer = document.getElementById('scrollContainer');
            const containerRect = scrollContainer.getBoundingClientRect();

            const scrollBy = 30;

            if (containerRect.left > 0 && e.evt.clientX < (containerRect.left + 50)) {
                scrollContainer.scrollLeft -= scrollBy;
            }

            if (e.evt.clientX > window.innerWidth - 50) {
                scrollContainer.scrollLeft += scrollBy;
            }

            if (containerRect.top > 0 && e.evt.clientY < (containerRect.top + 50)) {
                scrollContainer.scrollTop -= scrollBy;
            }

            if (e.evt.clientY > window.innerHeight - 50) {
                scrollContainer.scrollTop += scrollBy;
            }


        });
        seatGroup.on('dragend', (e) => {
            seatGroup.position({
                x: Math.round(seatGroup.x() / blockSnapSize) * blockSnapSize,
                y: Math.round(seatGroup.y() / blockSnapSize) * blockSnapSize
            });
            stage.batchDraw();
            shadowRectangle.hide();
        });


        // Add to layer
        this.layer.add(seatGroup);
        this.layer.draw();

        localStorage.setItem('seatingPlan', this.stage.toJSON());

        return false;
    }

    addArea = (event, type) => {
        event.preventDefault();

        let size = prompt("Please enter the width of the area", "35");
        let heightRectangle = null;

        if (size === null || size === "") return false;
        else {

            size = parseInt(size);

            if (type === 'rectangle') {
                // prompt for height
                heightRectangle = prompt("Please enter the height of the area", "35");

                if (heightRectangle === null || heightRectangle === "") return false;
                else heightRectangle = parseInt(heightRectangle);
            }
        }

        let lastX = 50;
        let lastY = 50;

        const lastGroup = this.layer.find("Group").pop();

        if (lastGroup) {
            lastX = lastGroup.x();
            lastY = lastGroup.y();
        }

        // Check the type of the area
        // Create shape accordingly
        // Add a transformer to the shape
        let shape;

        if (type === 'circle') {
            shape = new Konva.Circle({
                name: 'drawn',
                radius: size,
                x: 0,
                y: 0,
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 1.5,
                stroke: '#000',
                // draggable: true
            });
        }

        if (type === 'rectangle') {
            shape = new Konva.Rect({
                name: 'drawn',
                width: size,
                height: heightRectangle,
                x: 0,
                y: 0,
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 1.5,
                stroke: '#000',
                // draggable: true
            });
        }

        // Add a label too
        // Increment label from last group
        const label = new Konva.Text({
            text: '1',
            fontSize: 12,
            // padding: 5,
            x: 7,
            y: 7,
            fontFamily: 'Arial',
            fill: 'black'
        });

        if (lastGroup) {
            const labelNum = parseInt(lastGroup.children[1].attrs.text) || 0;
            label.text(labelNum + 1);
        }


        const areaGroup = new Konva.Group({
            x: lastX + size,
            y: lastY,
            draggable: true
        });

        areaGroup.add(shape);
        areaGroup.add(label);


        // make shape's selectable attr true by default
        areaGroup.setAttr('selectable', true);

        // Add to layer
        this.layer.add(areaGroup);
        this.layer.draw();

        return false;



    }

    addBackgroundImage = (event) => {
        event.preventDefault();

        // prompt for image URL
        let url = prompt("Please enter the URL of the image", "");

        if (url === null || url === "") return false;

        // add image and update layer
        const image = new window.Image();
        image.src = url;

        let layer = this.layer;

        image.onload = () => {
            const konvaImage = new Konva.Image({
                image: image,
                x: 0,
                y: 0,
                width: image.width,
                height: image.height
            });

            konvaImage.setAttr('source', url);

            layer.add(konvaImage);
            layer.draw();
        }

        return false;
    }

    addBackgroundImageForDisplay = (url) => {
        const image = new window.Image();
        image.src = url;

        let thisLayer = this.layer;
        let thisStage = this.stage;

        // create a new layer for the image
        const layer = new Konva.Layer();

        image.onload = () => {
            const konvaImage = new Konva.Image({
                image: image,
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
            });

            layer.add(konvaImage);
            layer.draw();

            layer.setZIndex(-10);

            // add layer to stage
            thisStage.add(layer);

            // set current layer to be above
            thisLayer.moveToTop();

        }
    }

    removeBackgroundImage = (event) => {
        event.preventDefault();

        const image = this.layer.find('Image').pop();
        if (image) {
            image.destroy();
            this.layer.draw();
        }

        this.setState({ selectedShapes: [] });
    }

    deleteShapes = (shapes) => {
        shapes.forEach(shape => {
            shape.destroy();
        });
        this.layer.draw();
        this.setState({ selectedShapes: [] });
    }

    cloneShape = (shape) => {

        // Ask for a label first, if no label, return
        // if label is not unique, alert and return

        const label = window.prompt('Enter a label for the cloned area:', shape.children[1].attrs.text);
        if (label === null) return;

        // chceck if label has been used before
        const shapes = this.layer.find('Group');
        const labels = shapes.map(shape => shape.children[1].attrs.text);
        if (labels.includes(label)) {
            alert('Label already exists, it must be unique');
            return;
        }

        const clonedShape = shape.clone({
            x: shape.x() + 10,
            y: shape.y() + 10
        });

        // Change the label
        clonedShape.children[1].text(label);

        this.layer.add(clonedShape);
        this.setState({ selectedShapes: [clonedShape] });
        this.layer.draw();
    }

    changeWidthHeight = (shapes) => {
        const RectShape = shapes[0].find('Rect')[0];
        const CircleShape = shapes[0].find('Circle')[0];

        if (RectShape) {
            const newWidth = window.prompt('Enter new width:', RectShape.width().toString());
            const newHeight = window.prompt('Enter new height:', RectShape.height().toString());
            if (newWidth !== null && newHeight !== null) {
                // parse the new width and height
                const width = parseInt(newWidth);
                const height = parseInt(newHeight);
                RectShape.width(width);
                RectShape.height(height);
                this.layer.draw();
            }
        }

        if (CircleShape) {
            const newRadius = window.prompt('Enter new radius:', CircleShape.radius().toString());
            if (newRadius !== null) {
                // parse the new radius
                const radius = parseInt(newRadius);
                CircleShape.radius(radius);
                this.layer.draw();
            }
        }
    }

    renameLabel = (shape) => {
        const newText = window.prompt('Enter new ID for the seat:', shape.children[1].attrs.text);
        if (newText !== null) {
            // check if the text is already in use
            const existingLabel = this.layer.find('Text').find(label => label.attrs.text === newText);
            if (existingLabel) {
                alert('ID must be unique.');
            } else {
                shape.children[1].text(newText);
                this.layer.draw();
                localStorage.setItem('seatingPlan', this.stage.toJSON());
            }
        }
    }

    changeBgColor = (shapes) => {
        const color = window.prompt('Enter new color:', shapes[0].children[0].attrs.fill);
        if (color !== null) {
            shapes.forEach(shape => {
                shape.children[0].fill(color);
                // also set attr bgColor
                shape.setAttr('bgColor', color);
            });
            this.layer.draw();
        }
    }

    changeFgColor = (shapes) => {
        const color = window.prompt('Enter new color:', shapes[0].children[1].attrs.fill);
        if (color !== null) {
            shapes.forEach(shape => {
                shape.children[1].fill(color);
                // also set attr fgColor
                shape.setAttr('fgColor', color);
            });
            this.layer.draw();
        }
    }

    assignTicket = (shapes) => {
        const { selectedTicket } = this.state;
        const { tickets } = this.props;

        if (selectedTicket) {
            const ticket = tickets.find(ticket => ticket.id === selectedTicket);
            shapes.forEach(shape => {
                // shape.children[1].text(ticket.id);  
                shape.setAttr('ticket', ticket.id);
                shape.children[0].fill(stringToColour(ticket.id));
                shape.children[1].fill('white');
            });
            this.layer.draw();

            this.setState({ selectedTicket: null });

            localStorage.setItem('seatingPlan', this.stage.toJSON());

        } else {
            alert('Please select a product first. If there is none, add a new product.');
        }
    }

    assignProductsToArea = (shapes) => {
        const { selectedAreaProducts } = this.state;

        if (selectedAreaProducts && selectedAreaProducts.length > 0) {

            shapes.forEach(shape => {
                shape.setAttr('tickets', JSON.stringify(selectedAreaProducts));
                shape.children[0].fill('green');

            });

            this.layer.draw();

            this.setState({ selectedAreaProducts: [] });

            localStorage.setItem('seatingPlan', this.stage.toJSON());

        } else {
            alert('Please select a product first. If there is none, add a new product.');
        }

    }

    render() {
        const { selectedShapes, layoutLoaded } = this.state;
        const { isAssignMode, tickets, layoutType, isStandardEvent, isViewOnly } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                {
                    !isViewOnly && (
                        <div style={{ marginLeft: '20px', minWidth: '200px', marginRight: 20 }}>

                            {/* TOP STATS, Assign, left etc. */}
                            {
                                isAssignMode && (
                                    <div style={{ border: '1px solid #AAA', padding: 5 }}>
                                        <h3>Stats</h3>
                                        {/* Total and available seats */}
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 10 }}>
                                                <span>Total {layoutType === 'seating' ? 'Seats' : 'Areas'}:</span>
                                                <span> {this.layer && this.layer.find('Group').length}</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                                {
                                                    layoutType === 'seating' ? (
                                                        null
                                                    ) : (
                                                        <span style={{ width: 20, height: 20, backgroundColor: 'green', display: 'inline-block', marginRight: 5 }}></span>
                                                    )
                                                }
                                                <span>Assigned {layoutType === 'seating' ? 'Seats' : 'Areas'}:</span>
                                                {
                                                    layoutType === 'seating' ? (
                                                        <span> {this.layer && this.layer.find('Group').filter(group => group.attrs.ticket).length}</span>
                                                    ) : (
                                                        <React.Fragment>
                                                            <span> {this.layer && this.layer.find('Group').filter(group => group.attrs.tickets).length}</span>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {
                                                    layoutType === 'seating' ? (
                                                        null
                                                    ) : (
                                                        <span style={{ width: 20, height: 20, backgroundColor: '#FFF', border: '1px solid black', display: 'inline-block', marginRight: 5 }}></span>
                                                    )
                                                }
                                                <span>Unassigned {layoutType === 'seating' ? 'Seats' : 'Areas'}:</span>
                                                {layoutType === 'seating' ? (
                                                    <span> {this.layer && this.layer.find('Group').filter(group => !group.attrs.ticket).length}</span>
                                                ) : (
                                                    <span> {this.layer && this.layer.find('Group').filter(group => !group.attrs.tickets).length}</span>
                                                )}

                                            </div>

                                            {/* List assigned tickets, by product type */}
                                            <div style={{ marginTop: 10 }}>
                                                {!isStandardEvent && <h4>Assigned Products/Tickets:</h4>}
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {
                                                        !isStandardEvent ? (
                                                            (tickets && tickets.length > 0) && tickets.map(ticket => {
                                                                const assignedSeats = this.layer && this.layer.find('Text').filter(label => label.parent.attrs.ticket === ticket.id);
                                                                return (
                                                                    <div key={ticket.id} style={{ display: 'flex', alignContent: 'center' }}>
                                                                        <span>
                                                                            <span style={{ backgroundColor: stringToColour(ticket.id), width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                                                        </span>
                                                                        <span>{ticket.title.tr}:</span>
                                                                        <span>{assignedSeats && assignedSeats.length > 0 ? assignedSeats.length : 0}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                            : (
                                                                null
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {/* SELECTED SEATS */}
                            {selectedShapes.length > 0 ? (

                                <React.Fragment>

                                    {(layoutType === 'seating') && (
                                        <div style={{ border: '1px solid #AAA', padding: 5, marginTop: 20 }}>
                                            <h3>Selected:</h3>
                                            <h4>{selectedShapes.length} seats</h4>
                                            <div style={{ maxWidth: 150 }}>
                                                {selectedShapes.map((shape, index) => {
                                                    let label = shape.children[1].attrs.text;

                                                    return label.concat(index + 1 === selectedShapes.length ? '' : ', ');
                                                })}
                                            </div>
                                            {
                                                !isAssignMode ? (
                                                    <div style={{ flexDirection: 'column' }}>
                                                        <h4>Create Actions:</h4>

                                                        <Button onClick={(event) => {
                                                            event.preventDefault();
                                                            this.deleteShapes(selectedShapes);
                                                        }} label="Delete" size="medium" startIcon={<DeleteIcon />} />

                                                        {selectedShapes.length === 1 &&
                                                            <div>
                                                                <Button onClick={(event) => {
                                                                    event.preventDefault();
                                                                    this.renameLabel(selectedShapes[0]);
                                                                }} label="Rename Seat ID" size="medium" startIcon={<EditIcon />} />
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    <div style={{ flexDirection: 'column' }}>
                                                        <h4>Assign Tickets:</h4>

                                                        <FormControl variant="outlined">
                                                            <InputLabel id="lblid123">Ticket</InputLabel>
                                                            <Select
                                                                labelId="lblid123"
                                                                value={this.state.selectedTicket}
                                                                onChange={(event) => this.setState({ selectedTicket: event.target.value })}
                                                                label="Ticket"
                                                                style={{ minWidth: 150 }}
                                                            >

                                                                {tickets.map(ticket => (
                                                                    <MenuItem value={ticket.id}>{ticket.title.tr}</MenuItem>
                                                                ))}

                                                            </Select>
                                                        </FormControl>

                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                            <Button onClick={(event) => {
                                                                event.preventDefault();
                                                                this.assignTicket(selectedShapes);
                                                            }} label="Assign" size="medium" startIcon={<EditIcon />} style={{ display: 'block' }} />

                                                            <Button onClick={(event) => {
                                                                event.preventDefault();
                                                                selectedShapes.forEach(shape => {
                                                                    shape.setAttr('ticket', null);
                                                                    shape.children[0].fill('white');
                                                                    shape.children[1].fill('black');
                                                                });
                                                                this.layer.draw();
                                                                localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                            }} label="Remove" size="medium" startIcon={<DeleteIcon />} style={{ display: 'block' }} />

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}

                                    {(layoutType === 'area') && (
                                        <div style={{ border: '1px solid #AAA', padding: 5, marginTop: 20 }}>
                                            <h3>Selected:</h3>
                                            <h4>{selectedShapes.length.toString().concat(' ', selectedShapes.length > 1 ? 'areas' : 'area')}</h4>
                                            <div style={{ maxWidth: 150 }}>
                                                {/* {selectedShapes.map((shape, index) => {
                                            let label = shape.attrs.name;
                                            return label.concat(index + 1 === selectedShapes.length ? '' : ', ');
                                        })} */}
                                            </div>

                                            {
                                                !isAssignMode ? (
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                            <Button onClick={(event) => {
                                                                event.preventDefault();
                                                                this.deleteShapes(selectedShapes);
                                                                this.layer.draw();
                                                                localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                            }} label="Remove" size="medium" startIcon={<DeleteIcon />} style={{ display: 'block' }} />

                                                            {/* Clone */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <Button onClick={(event) => {
                                                                        event.preventDefault();
                                                                        this.cloneShape(selectedShapes[0]);
                                                                        this.layer.draw();
                                                                        localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                                    }} label="Clone" size="medium" startIcon={<CopyAllOutlined />} style={{ display: 'block' }} />
                                                                )
                                                            }

                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #eee' }}>
                                                            {/* Change width & height of a rectangle */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.changeWidthHeight(selectedShapes);
                                                                            this.layer.draw();
                                                                            localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                                        }} label="Edit Size" size="medium" startIcon={<PhotoSizeSelectActual />} style={{ display: 'block' }} />
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <Button onClick={(event) => {
                                                                        event.preventDefault();
                                                                        this.renameLabel(selectedShapes[0]);
                                                                        this.layer.draw();
                                                                        localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                                    }} label="Rename" size="medium" startIcon={<EditIcon />} style={{ display: 'block' }} />
                                                                )
                                                            }
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #eee' }}>
                                                            {/* Change bg color */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.changeBgColor(selectedShapes);
                                                                            this.layer.draw();
                                                                            localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                                        }} label="BG Color" size="medium" startIcon={<FormatColorFill />} style={{ display: 'block' }} />
                                                                    </React.Fragment>
                                                                )
                                                            }


                                                            {/* Change fg color */}

                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.changeFgColor(selectedShapes);
                                                                            this.layer.draw();
                                                                            localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                                        }} label="FG Color" size="medium" startIcon={<FormatColorText />} style={{ display: 'block' }} />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #eee' }}>
                                                            {/* Selectable/Unselectable switch */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={selectedShapes[0].attrs.selectable}
                                                                                    onChange={(event) => {
                                                                                        // console.log(selectedShapes[0]);
                                                                                        selectedShapes[0].setAttr('selectable', event.target.checked);
                                                                                        this.layer.draw();
                                                                                        localStorage.setItem('seatingPlan', this.stage.toJSON());

                                                                                        this.setState({ selectedShapes: [] });
                                                                                    }}
                                                                                    name="selectable"
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label="Selectable"
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <h4>View Products:</h4>

                                                        {
                                                            selectedShapes.length > 1 ? (
                                                                "Please select only one area to view products"
                                                            ) : (
                                                                <React.Fragment>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        {
                                                                            selectedShapes[0].attrs.tickets && selectedShapes[0].attrs.tickets.length > 0 && JSON.parse(selectedShapes[0].attrs.tickets).length > 0 ? (
                                                                                JSON.parse(selectedShapes[0].attrs.tickets).map((product, index) => {


                                                                                    let ticket = null;

                                                                                    standardEventTicketGroups.forEach(group => {
                                                                                        const ticketFound = tickets[group].find(t => t.id === product);
                                                                                        if (ticketFound) ticket = ticketFound;
                                                                                    });

                                                                                    if (!ticket) {
                                                                                        return null;
                                                                                    }

                                                                                    return (
                                                                                        <div key={ticket.id}>
                                                                                            {ticket.title.tr}
                                                                                            {
                                                                                                ticket.price ? (
                                                                                                    <span style={{ fontSize: 10, color: 'gray' }}> - {ticket.price}TL</span>
                                                                                                ) : null
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            ) : (
                                                                                <div>No products assigned yet.</div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }


                                                        <h4>Assign Products:</h4>
                                                        {
                                                            selectedShapes.length > 1 ? (
                                                                "Please select only one area to assign products"
                                                            ) : (
                                                                <React.Fragment>

                                                                    <FormControl variant="outlined">
                                                                        <InputLabel id="lblid1234">Products</InputLabel>
                                                                        <Select
                                                                            labelId="lblid1234"
                                                                            value={this.state.selectedAreaProducts}
                                                                            onChange={(event) => {
                                                                                this.setState({ selectedAreaProducts: event.target.value });
                                                                            }}
                                                                            label="Product"
                                                                            style={{ minWidth: 150 }}
                                                                            multiple
                                                                        >
                                                                            {/* ticket */}
                                                                            {standardEventTicketGroups.map(ticketGroup => (
                                                                                tickets[ticketGroup].map(ticket => (
                                                                                    <MenuItem key={ticket.id} value={ticket.id}>{ticket.title.tr}</MenuItem>
                                                                                ))
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>

                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.assignProductsToArea(selectedShapes);
                                                                        }} label="Assign" size="medium" startIcon={<EditIcon />} style={{ display: 'block' }} />

                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            selectedShapes.forEach(shape => {
                                                                                shape.setAttr('tickets', null);
                                                                                shape.children[0].fill('white');
                                                                            });
                                                                            this.layer.draw();
                                                                            localStorage.setItem('seatingPlan', this.stage.toJSON());
                                                                        }} label="Remove" size="medium" startIcon={<DeleteIcon />} style={{ display: 'block' }} />

                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                        </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                <div>
                                    <br /><br />
                                    {isAssignMode ? `Select ${layoutType === 'seating' ? 'seats' : 'an area'} to assign a product` : `Create ${layoutType === 'seating' ? 'seats' : 'areas'} to see relevant actions`}
                                </div>
                            )}
                        </div>
                    )
                }

                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
                    {
                        (!isAssignMode && !isViewOnly) && (
                            layoutType !== 'area' ? (
                                <div style={{ borderBottom: '2px solid black' }}>
                                    <Button onClick={(event) => this.addShape(event, 'rect')} label="Add Seat" size="medium" startIcon={<AddIcon />} />
                                </div>
                            ) : (
                                <React.Fragment>

                                    {this.layer && this.layer.find('Image').length === 0 ? (
                                        <Button onClick={(event) => this.addBackgroundImage(event)} label="Add Background (Image)" size="medium" startIcon={<AddIcon />} />
                                    ) : (
                                        <Button onClick={(event) => this.removeBackgroundImage(event)} label="Remove Background (Image)" size="medium" startIcon={<DeleteIcon />} />
                                    )}

                                    <Button onClick={(event) => this.addArea(event, 'rectangle')} label="Add Area (Reactangle)" size="medium" startIcon={<AddIcon />} />
                                    <Button onClick={(event) => this.addArea(event, 'circle')} label="Add Area (Circle)" size="medium" startIcon={<AddIcon />} />
                                </React.Fragment>
                            )
                        )
                    }
                    {/* TOP ACTION PANEL & THE STAGE */}
                    <div id="scrollContainer" style={{ overflow: 'scroll', width: window.innerWidth / 1.5, height: window.innerHeight / 1.5 }}>
                        {
                            !isAssignMode ? (
                                <Stage
                                    width={width} height={height}
                                    ref={node => node && (this.stage = node.getStage())}>
                                    <Layer ref={node => this.layer = node}></Layer>
                                </Stage>
                            ) : (
                                <div id="container" style={{ width: width, height: height }}></div>
                            )
                        }

                        {
                            isViewOnly && (
                                <div id="container" style={{ width: width, height: height }}></div>
                            )
                        }
                    </div>
                    {layoutType !== 'area' && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 50, backgroundColor: 'lightgrey' }}>
                            <div>STAGE</div>
                        </div>
                    )}
                </div>

            </div>
        );
    }
}

// UTILS
function stringToColour(idString) {
    let idNumber = parseInt(idString, 36);

    let red = (idNumber & 0xFF0000) >> 16;
    let green = (idNumber & 0x00FF00) >> 8;
    let blue = idNumber & 0x0000FF;

    let luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    // not too bright please..
    if (luminance > 0.7) {
        red = Math.floor(red * 0.7);
        green = Math.floor(green * 0.7);
        blue = Math.floor(blue * 0.7);
    }

    let color = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);


    return color;
}

export default SeatingPlanCanvas;