import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, BooleanInput,
    ReferenceArrayInput, AutocompleteArrayInput, CheckboxGroupInput, ImageInput, 
    ImageField, required, ReferenceInput, SelectInput, ReferenceField, BooleanField,
    ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from "../components/form/InputRow";

// Entity
const entity = "salePoints";

const filters = [
    <TextInput source="name" label="Name" alwaysOn />,
    <ReferenceInput source="city" reference="cities" label="City" alwaysOn>
        <SelectInput source="name.en" optionText={"name.en"} />
    </ReferenceInput>,
    <BooleanInput source="isActive" label="Active Filter" />,
    <CheckboxGroupInput source="paymentMethods" choices={[
        { id: 'cc', name: 'Credit Card' },
        { id: 'cash', name: 'Cash' },
        { id: 'btc', name: 'Bitcoin' }
    ]} />
]

export const SalePointList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Sale Points" filters={filters} exporter={false}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="name" label={"Name"} />
            <ReferenceField source="city" reference="cities" label={"City"} link={false}>
                <TextField source="name.en" />
            </ReferenceField>
            <BooleanField source="isActive" label="Is Active?" />
            <ReferenceArrayField source="managers" reference="users" label="Managers">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

export const SalePointEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Actions</h3>
            <BooleanInput source="isActive" label="Show sale point on website?" helperText="Please note that the sale point should be 'saved' for this change to take effect." />
            <h3>Details</h3>
            <TextInput source="name" label={"Name"} fullWidth autoComplete="off" validate={required()} />
            <ReferenceInput source="city" reference="cities">
                <SelectInput source="name" optionText={"name.en"} fullWidth validate={required()} />
            </ReferenceInput>
            <InputRow>
                <TextInput source="coordinates.long" label={"Longitude"} fullWidth autoComplete="off" />
                <TextInput source="coordinates.lat" label={"Latitude"} fullWidth autoComplete="off" />
            </InputRow>
            <TextInput source="description" label={"Description"} fullWidth autoComplete="off" rows={3} multiline />
            <h3>Photo (Logo)</h3>
            <ImageInput source="banner" label=" " accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <h3>Payment Methods</h3>
            <CheckboxGroupInput source="paymentMethods" choices={[
                { id: 'cc', name: 'Credit Card' },
                { id: 'cash', name: 'Cash' },
                { id: 'btc', name: 'Bitcoin' }
            ]} validate={required()} />
            <h3>Managers</h3>
            <ReferenceArrayInput source="managers" reference="users">
                <AutocompleteArrayInput optionText={(r) => r.email + (r.crole ? ` (${r.crole})` : '')} fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} clearOnBlur={false} />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const SalePointCreate = (props) => {
    // const { permissions } = usePermissions();
    // if (!permissions) return;

    return (
        <Create title="New" {...props}>
            <SimpleForm redirect={`/${entity}`}>
                <h3>Details</h3>
                <TextInput source="name" label={"Name"} fullWidth autoComplete="off" validate={required()} />
                <TextInput source="telephone" label={"Telephone"} fullWidth autoComplete="off" validate={required()} />
                <TextInput source="description" label={"Description"} fullWidth autoComplete="off" rows={3} multiline />
                <h3>Address</h3>
                <ReferenceInput source="city" reference="cities">
                    <SelectInput source="name" optionText={"name.en"} fullWidth validate={required()} />
                </ReferenceInput>
                <TextInput source="address" label={"Address"} fullWidth autoComplete="off" validate={required()} />
                <InputRow>
                    <TextInput source="coordinates.long" label={"Longitude"} fullWidth autoComplete="off" />
                    <TextInput source="coordinates.lat" label={"Latitude"} fullWidth autoComplete="off" />
                </InputRow>
                <h3>Photo (Logo)</h3>
                <ImageInput source="banner" label=" " accept="image/*" defaultValue={null}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <h3>Payment Methods</h3>
                <CheckboxGroupInput source="paymentMethods" choices={[
                    { id: 'cc', name: 'Credit Card' },
                    { id: 'cash', name: 'Cash' },
                    { id: 'btc', name: 'Bitcoin' }
                ]} validate={required()} />
                <h3>Managers</h3>
                <ReferenceArrayInput source="managers" reference="users">
                    <AutocompleteArrayInput optionText={(r) => r.email + (r.crole ? ` (${r.crole})` : '')} fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} clearOnBlur={false} />
                </ReferenceArrayInput>
                <div style={{ visibility: 'hidden' }}>
                    <BooleanInput source="isActive" value={true} checked={true} label="Show this sale point on website?" helperText="Please note that the sale point should be 'saved' for this change to take effect." hidden={true} disabled={true} />
                </div>
            </SimpleForm>
        </Create>
    );
}
