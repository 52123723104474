import * as React from "react";
import {
    List, Datagrid, Edit, SimpleForm, TextField, EditButton,
    required, ReferenceInput, BooleanInput, FunctionField, SearchInput,
    TextInput, DateInput, SelectInput, SelectField, BooleanField, NumberInput, ExportButton,
    TopToolbar, FilterButton
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from '../components/form/InputRow';
import NoDeleteToolbar from '../components/form/NoDeleteToolbar';

// Libs
import moment from "moment";
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';

// Constants
import COUNTRIES from '../constants/countries';
import GENDERS from '../constants/genders';

// Entity
const entity = "users";

const filters = [
    <SearchInput source="name" />,
    <SearchInput source="mobile" />,
    <SearchInput source="email" />,
    <DateInput source="createdate" label="Membership Date" />,
    <SelectInput source="country" choices={COUNTRIES} />,
    <ReferenceInput source="city" reference="cities">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
    <BooleanInput source="isVerified" label={"Verified"} defaultChecked={true} defaultValue={true} />,
    // <BooleanInput source="isLegacyUser" label={"Legacy User"} defaultChecked={false} defaultValue={false} />,
    <SelectInput source="gender" choices={GENDERS} />
]

const exporter = (records) => {
    console.log(records.length);

    const data = records.map(record => {
        return {
            "User": `${record.name} ${record.surname}`,
            "Gender": record.gender === 'male' ? "Male" : "Female",
            "Mobile": record.mobile,
            "Email": record.email,
            "Birthday": moment(record.dob, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            "Country": (record.country && record.country !== "") ? record.country : '-',
            "City": (record.city && record.city !== "") ? record.city : '-',
            "Verified": record.isVerified ? 'Yes' : 'No',
            "Newsletter": record.newsletter ? 'Yes' : 'No',
            "Membership Date": moment(record.createdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        }
    });

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-users-${moment().format('LL-LT')}.xlsx`);
    });

}

const CustomExportButton = () => {
    return <ExportButton exporter={exporter} />
}

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <CustomExportButton />
    </TopToolbar>
);

export const UserList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} filters={filters} sort={{ field: 'createDateTime', order: 'DESC' }} actions={<ListActions />}>
        <Datagrid>
            <FunctionField label="User" render={record => `${record.name} ${record.surname}`} />
            <FunctionField label="Gender" render={record => `${record.gender === 'male' ? "M" : "F"}`} />
            <TextField source="mobile" />
            <FunctionField source="id" label="Joined" sortBy={'createDateTime'} render={r => {
                return r.createDateTime ? moment(r.createDateTime, 'x').format("LL, LT") : moment(r.createdate).format("LL")
            }} />
            <SelectField source="country" choices={COUNTRIES} />
            <BooleanField source="isVerified" label="Verified" />
            {/* <BooleanField source="isLegacyUser" label="Legacy User" /> */}
            <TextField source="email" />
            <EditButton />
        </Datagrid>
    </List>
);

export const UserEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`} toolbar={<NoDeleteToolbar />}>
            <FunctionField source="name" render={r => `${r.name} ${r.surname}`} fontSize={25} />
            <hr />
            <InputRow>
                <TextInput source="name" fullWidth validate={required()} />
                <TextInput source="surname" fullWidth validate={required()} />
                <SelectInput source="gender" choices={GENDERS} fullWidth validate={required()} />
            </InputRow>
            <InputRow>
                <TextInput source="mobile" fullWidth validate={required()} />
                <TextInput source="email" fullWidth validate={required()} disabled />
            </InputRow>
            <InputRow>
                <SelectInput source="country" choices={COUNTRIES} fullWidth validate={required()} disabled />
                <ReferenceInput source="city" reference="cities" defaultValue={null}>
                    <SelectInput optionText={"name.en"} fullWidth disabled defaultValue={null} />
                </ReferenceInput>
            </InputRow>
            <InputRow>
                <DateInput source='createdate' fullWidth label="Membership Date" disabled />
                <DateInput source="dob" fullWidth validate={required()} disabled label="Date of birth" />
            </InputRow>
            <BooleanInput source="newsletter" label={"Newsletter Subscription"} fullWidth validate={required()} />
            <BooleanInput source="isVerified" label={"Verified User"} fullWidth validate={required()} />
            <NumberInput source="verificationCode" label="Verification Code" helperText="When you change this code, a new verification email will be sent to the user" />
        </SimpleForm>
    </Edit>
);

// export const UserCreate = (props) => (
//     <Create title="Create" {...props}>
//         <SimpleForm redirect={`/${entity}`}>
//             <InputRow>
//                 <TextInput source="name" fullWidth validate={required()} />
//                 <TextInput source="surname" fullWidth validate={required()} />
//                 <SelectInput source="gender" choices={GENDERS} fullWidth validate={required()} />
//             </InputRow>
//             <InputRow>
//                 <TextInput source="mobile" fullWidth validate={required()} />
//                 <TextInput source="email" fullWidth validate={required()} />
//             </InputRow>
//             <InputRow>
//                 <SelectInput source="country" choices={COUNTRIES} fullWidth validate={required()} />
//                 <ReferenceInput source="city" reference="cities">
//                     <SelectInput optionText={"name.en"} fullWidth />
//                 </ReferenceInput>
//             </InputRow>
//             <DateInput source="dob" fullWidth validate={required()} />
//             <BooleanInput source="newsletter" label={"Newsletter Subscription"} fullWidth validate={required()} />
//         </SimpleForm>
//     </Create>
// );
