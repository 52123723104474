import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    required, AutocompleteInput, useEditContext, 
    Toolbar, SaveButton, ReferenceInput, ReferenceField, SelectInput, FormDataConsumer, FunctionField
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import SeatingPlanEditor from "../components/seating/SeatingPlanEditor";

// UI
import { Box } from '@mui/material';

// Entity
const entity = "layouts";

const layoutTypes = [
    { id: 'seating', name: 'Seat Plan' },
    { id: 'area', name: 'Area' },
]

const t = {
    "attrs": {
        "width": 2000,
        "height": 2000
    },
    "className": "Stage",
    "children": [
        {
            "attrs": {},
            "className": "Layer",
            "children": [
                {
                    "attrs": {
                        "x": 35,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A28",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 280,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 490,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 665,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "C1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 630,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "D1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 595,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "E1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 560,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "F1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 525,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "G1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 490,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "H1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 455,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "J1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 735,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "A1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 315,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "N1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 280,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "O1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 245,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "P1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 210,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "R1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 175,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "S1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 140,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "T1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1260,
                        "y": 70,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "V1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y17",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 700,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y16",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 735,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y8",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1015,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y7",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1050,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y6",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1085,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y5",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1120,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y4",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1155,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y3",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1190,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y2",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 1225,
                        "y": 35,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "Y1",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 770,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L15",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 910,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K11",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 945,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L10",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 980,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L9",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 840,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L13",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 805,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L14",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 140,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L32",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 210,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L30",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 525,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M21",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 315,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M27",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 245,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M29",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 175,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M31",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 105,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M33",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 70,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M34",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 35,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M35",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 350,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L26",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L24",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 455,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L23",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 560,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L20",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 595,
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L19",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 420,
                        "y": 700,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "B22",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 875,
                        "y": 420,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "K12",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "y": 385,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "L36",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 665,
                        "y": 350,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "M18",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 105,
                        "width": 35,
                        "height": 35,
                        "fill": "#FF7B17",
                        "opacity": 0.6,
                        "stroke": "#CF6412",
                        "strokeWidth": 3,
                        "dash": [
                            20,
                            2
                        ],
                        "visible": false
                    },
                    "className": "Rect"
                },
                {
                    "attrs": {
                        "x": 385,
                        "y": 105,
                        "draggable": true
                    },
                    "className": "Group",
                    "children": [
                        {
                            "attrs": {
                                "name": "drawn",
                                "width": 35,
                                "height": 35,
                                "scaleX": 0.9,
                                "scaleY": 0.9,
                                "x": 2,
                                "y": 2,
                                "fill": "#FFF",
                                "stroke": "#000",
                                "strokeWidth": 1.5,
                                "prevBGColor": "#FFF"
                            },
                            "className": "Rect"
                        },
                        {
                            "attrs": {
                                "text": "U25",
                                "x": 7,
                                "y": 7,
                                "fill": "black"
                            },
                            "className": "Text"
                        }
                    ]
                }
            ]
        },
        {
            "attrs": {},
            "className": "Layer",
            "children": [
                {
                    "attrs": {
                        "points": [
                            0.5,
                            0,
                            0.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            35.5,
                            0,
                            35.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            70.5,
                            0,
                            70.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            105.5,
                            0,
                            105.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            140.5,
                            0,
                            140.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            175.5,
                            0,
                            175.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            210.5,
                            0,
                            210.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            245.5,
                            0,
                            245.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            280.5,
                            0,
                            280.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            315.5,
                            0,
                            315.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            350.5,
                            0,
                            350.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            385.5,
                            0,
                            385.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            420.5,
                            0,
                            420.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            455.5,
                            0,
                            455.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            490.5,
                            0,
                            490.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            525.5,
                            0,
                            525.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            560.5,
                            0,
                            560.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            595.5,
                            0,
                            595.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            630.5,
                            0,
                            630.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            665.5,
                            0,
                            665.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            700.5,
                            0,
                            700.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            735.5,
                            0,
                            735.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            770.5,
                            0,
                            770.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            805.5,
                            0,
                            805.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            840.5,
                            0,
                            840.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            875.5,
                            0,
                            875.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            910.5,
                            0,
                            910.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            945.5,
                            0,
                            945.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            980.5,
                            0,
                            980.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1015.5,
                            0,
                            1015.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1050.5,
                            0,
                            1050.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1085.5,
                            0,
                            1085.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1120.5,
                            0,
                            1120.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1155.5,
                            0,
                            1155.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1190.5,
                            0,
                            1190.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1225.5,
                            0,
                            1225.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1260.5,
                            0,
                            1260.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1295.5,
                            0,
                            1295.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1330.5,
                            0,
                            1330.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1365.5,
                            0,
                            1365.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1400.5,
                            0,
                            1400.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1435.5,
                            0,
                            1435.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1470.5,
                            0,
                            1470.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1505.5,
                            0,
                            1505.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1540.5,
                            0,
                            1540.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1575.5,
                            0,
                            1575.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1610.5,
                            0,
                            1610.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1645.5,
                            0,
                            1645.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1680.5,
                            0,
                            1680.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1715.5,
                            0,
                            1715.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1750.5,
                            0,
                            1750.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1785.5,
                            0,
                            1785.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1820.5,
                            0,
                            1820.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1855.5,
                            0,
                            1855.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1890.5,
                            0,
                            1890.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1925.5,
                            0,
                            1925.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1960.5,
                            0,
                            1960.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            1995.5,
                            0,
                            1995.5,
                            2000
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 1
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            0,
                            10,
                            10
                        ]
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            0,
                            2000,
                            0
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            35,
                            2000,
                            35
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            70,
                            2000,
                            70
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            105,
                            2000,
                            105
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            140,
                            2000,
                            140
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            175,
                            2000,
                            175
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            210,
                            2000,
                            210
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            245,
                            2000,
                            245
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            280,
                            2000,
                            280
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            315,
                            2000,
                            315
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            350,
                            2000,
                            350
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            385,
                            2000,
                            385
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            420,
                            2000,
                            420
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            455,
                            2000,
                            455
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            490,
                            2000,
                            490
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            525,
                            2000,
                            525
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            560,
                            2000,
                            560
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            595,
                            2000,
                            595
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            630,
                            2000,
                            630
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            665,
                            2000,
                            665
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            700,
                            2000,
                            700
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            735,
                            2000,
                            735
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            770,
                            2000,
                            770
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            805,
                            2000,
                            805
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            840,
                            2000,
                            840
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            875,
                            2000,
                            875
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            910,
                            2000,
                            910
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            945,
                            2000,
                            945
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            980,
                            2000,
                            980
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1015,
                            2000,
                            1015
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1050,
                            2000,
                            1050
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1085,
                            2000,
                            1085
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1120,
                            2000,
                            1120
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1155,
                            2000,
                            1155
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1190,
                            2000,
                            1190
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1225,
                            2000,
                            1225
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1260,
                            2000,
                            1260
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1295,
                            2000,
                            1295
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1330,
                            2000,
                            1330
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1365,
                            2000,
                            1365
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1400,
                            2000,
                            1400
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1435,
                            2000,
                            1435
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1470,
                            2000,
                            1470
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1505,
                            2000,
                            1505
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1540,
                            2000,
                            1540
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1575,
                            2000,
                            1575
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1610,
                            2000,
                            1610
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1645,
                            2000,
                            1645
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1680,
                            2000,
                            1680
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1715,
                            2000,
                            1715
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1750,
                            2000,
                            1750
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1785,
                            2000,
                            1785
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1820,
                            2000,
                            1820
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1855,
                            2000,
                            1855
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1890,
                            2000,
                            1890
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1925,
                            2000,
                            1925
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1960,
                            2000,
                            1960
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                },
                {
                    "attrs": {
                        "points": [
                            0,
                            1995,
                            2000,
                            1995
                        ],
                        "stroke": "#ddd",
                        "strokeWidth": 0.5
                    },
                    "className": "Line"
                }
            ]
        }
    ]
};

export const LayoutList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} exporter={false} perPage={50}>
        <Datagrid 
        bulkActionButtons={false}
        >
            <TextField source="name" label={"Name"} />
            <ReferenceField source="venue" reference="venues" label="Venue">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField source="layoutType" render={r => {
                const layoutType = layoutTypes.find(l => l.id === r.layoutType);
                return layoutType ? layoutType.name : '';
            }} />
            <EditButton label="VIEW" icon={null} startIcon={null} endIcon={null} />
        </Datagrid>
    </List>
);

export const LayoutEdit = (props) => {
    return (
        <Edit title="Edit" {...props} actions={false}>
            <LayoutEditForm />
        </Edit>
    )
}
const LayoutEditForm = (...props) => {
    const { record } = useEditContext();

    return (
        <SimpleForm redirect={`/${entity}`} toolbar={false}>
            <TextInput source="name" label={"Name"} fullWidth />
            <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()} disabled />
            </ReferenceInput>
            {
                (record && record.id) ? (
                    <React.Fragment>
                        <div style={{ marginBottom: 10, color: 'red' }}>
                            Layouts can not be edited after they are created. If an alternative layout is needed for the venue please consider creating a new one.
                        </div>`
                        <SeatingPlanEditor record={record} isViewOnly={true} isAssignMode={true} />
                    </React.Fragment>
                ) : (
                    <div style={{ marginBottom: 10, color: 'red' }}>
                        There was an error loading the layout.
                    </div>
                )
            }
        </SimpleForm>
    )
};

const LayoutCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            transform={data => {
                const seatingPlan = localStorage.getItem('seatingPlan');
                console.log(seatingPlan);
                if (seatingPlan) localStorage.removeItem('seatingPlan');
                return { ...data, notify: true, layout: seatingPlan ?? null };
            }}
            type="button"
        />
    </Toolbar>
);

export const LayoutCreate = (props) => {
    return (
        <Create title="New" {...props} redirect={'list'}>
            <SimpleForm redirect={'list'} toolbar={<LayoutCreateToolbar />}>
                <TextInput source="name" label={"Name"} fullWidth validate={required()} />

                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput source="layoutType" label="Type" choices={layoutTypes} fullWidth defaultValue={'seating'} validate={required()} />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                            <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()} />
                        </ReferenceInput>
                    </Box>
                </Box>

                <FormDataConsumer>
                    {({ formData }) => <SeatingPlanEditor record={props.record} layoutType={formData.layoutType} isAssignMode={false} isCreateMode={true} />}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}
