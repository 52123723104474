import * as React from 'react';
import { Menu, MenuItemLink, usePermissions } from 'react-admin';

import {
    Domain, PeopleAlt, DoorFront, TagSharp,
    Category, CategoryOutlined, JoinFull, DashboardCustomize,
    BackupTable, Receipt, BurstMode, Image, Article, Widgets,
    CreditCard, ConfirmationNumber, Newspaper, PointOfSale,
    PersonPin, ListAlt, PeopleOutline, EmojiPeople
} from '@mui/icons-material';
import { FileTableBoxMultiple } from 'mdi-material-ui';

const VERSION = 'v2.2.469';

const CustomMenu = (props) => {
    const { permissions } = usePermissions();
    if (!permissions) return null;

    switch (permissions.crole) {
        case "venueManager":
            return (
                <Menu {...props}>
                    <div style={{ background: 'rgb(156,39,176)', marginTop: -10, textAlign: 'center' }}>
                        <h3 style={{ color: 'white' }}>Venue Manager</h3>
                        <h4 style={{ color: 'white' }}>{permissions.email}</h4>
                        <h6 style={{ color: '#EEE', margin: 0, padding: 0, marginTop: -20, marginBottom: 10 }}>{VERSION}</h6>
                    </div>
                    {/* <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Sales</h5>
                    <MenuItemLink to="/sales-old" primaryText="Sales" leftIcon={<Receipt />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/tickets-old" primaryText="Tickets" leftIcon={<ConfirmationNumber />} style={{ fontSize: 12.5 }} /> */}

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Events</h5>
                    <MenuItemLink to="/events" primaryText="Events" leftIcon={<Image />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/sessionEvents?filter=%7B%7D" primaryText="Session Events" leftIcon={<Image />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Venue</h5>
                    <MenuItemLink to="/venues" primaryText="Edit" leftIcon={<DoorFront />} style={{ fontSize: 12.5 }} />
                </Menu>
            );
        case "organisationCompanyManager":
            return (
                <Menu {...props}>
                    <div style={{ background: 'rgb(156,39,176)', marginTop: -10, textAlign: 'center' }}>
                        <h3 style={{ color: 'white' }}>Company Manager</h3>
                        <h4 style={{ color: 'white' }}>{permissions.email}</h4>
                        <h6 style={{ color: '#EEE', margin: 0, padding: 0, marginTop: -20, marginBottom: 10 }}>{VERSION}</h6>
                    </div>
                    {/* <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Sales</h5>
                    <MenuItemLink to="/sales-old" primaryText="Sales" leftIcon={<Receipt />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/tickets-old" primaryText="Tickets" leftIcon={<ConfirmationNumber />} style={{ fontSize: 12.5 }} /> */}

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Events</h5>
                    <MenuItemLink to="/events" primaryText="Events" leftIcon={<Image />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/sessionEvents?filter=%7B%7D" primaryText="Session Events" leftIcon={<Image />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Company</h5>
                    <MenuItemLink to="/organisationCompanies" primaryText="Edit" leftIcon={<JoinFull />} style={{ fontSize: 12.5 }} />
                </Menu>
            );
        case "affiliate":
            return (
                <Menu {...props}>
                    <div style={{ background: 'rgb(156,39,176)', marginTop: -10, textAlign: 'center' }}>
                        <h3 style={{ color: 'white' }}>Affiliate</h3>
                        <h4 style={{ color: 'white' }}>{permissions.email}</h4>
                        <h6 style={{ color: '#EEE', margin: 0, padding: 0, marginTop: -20, marginBottom: 10 }}>{VERSION}</h6>
                    </div>
                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Sales</h5>
                    {/* <MenuItemLink to="/sales-old" primaryText="Sales" leftIcon={<Receipt />} style={{ fontSize: 12.5 }} /> */}
                </Menu>
            );
        case "admin":
            return (
                <Menu {...props}>
                    <div style={{ background: 'rgb(156,39,176)', marginTop: -10, textAlign: 'center' }}>
                        <h3 style={{ color: 'white' }}>Admin</h3>
                        <h5 style={{ color: 'white' }}>{permissions.email}</h5>
                        <h6 style={{ color: '#EEE', margin: 0, padding: 0, marginTop: -20, marginBottom: 10 }}>{VERSION}</h6>
                    </div>

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Dashboard</h5>
                    <MenuItemLink to="/dashboard" primaryText="Dashboard" leftIcon={<FileTableBoxMultiple />} style={{ fontSize: 12.5 }} />
                    

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Events</h5>
                    <MenuItemLink to="/events?filter=%7B%7D" primaryText="Events" leftIcon={<Image />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/sessionEvents?filter=%7B%7D" primaryText="Session Events" leftIcon={<Image />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/groupEvents?filter=%7B%7D" primaryText="Group Events" leftIcon={<BurstMode />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Sales</h5>
                    <MenuItemLink to="/sales?last=50" primaryText="Sales" leftIcon={<Receipt />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/tickets" primaryText="Tickets" leftIcon={<ConfirmationNumber />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>People</h5>
                    <MenuItemLink to="/users?filter=%7B%7D" primaryText="Users" leftIcon={<PeopleAlt />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/affiliates?filter=%7B%7D" primaryText="Affiliates" leftIcon={<PersonPin />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/saleAgents?filter=%7B%7D" primaryText="Sale Agents" leftIcon={<PeopleOutline />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/promoters?filter=%7B%7D" primaryText="Promoters" leftIcon={<EmojiPeople />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/affiliateForm?filter=%7B%7D" primaryText="Affiliate Form" leftIcon={<PersonPin />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/deleteUserRequests" primaryText="Delete Requests" leftIcon={<PeopleOutline />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Places</h5>
                    <MenuItemLink to="/venues?filter=%7B%7D" primaryText="Venues" leftIcon={<DoorFront />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/layouts?filter=%7B%7D" primaryText="Layouts" leftIcon={<Article />} style={{ fontSize: 12.5 }} />

                    <MenuItemLink to="/organisationCompanies?filter=%7B%7D" primaryText="Org. Companies" leftIcon={<JoinFull />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/salePoints?filter=%7B%7D" primaryText="Sale Points" leftIcon={<PointOfSale />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Definitions</h5>
                    <MenuItemLink to="/featureds" primaryText="Featured" leftIcon={<FileTableBoxMultiple />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/categories" primaryText="Categories" leftIcon={<Category />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/subcategories" primaryText="Sub Categories" leftIcon={<CategoryOutlined />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/venueCategories" primaryText="Venue Categories" leftIcon={<BackupTable />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/cities" primaryText="Cities" leftIcon={<Domain />} style={{ fontSize: 12.5 }} />
                    {/* <MenuItemLink to="/eventTags" primaryText="Event Tags" leftIcon={<TagSharp />} style={{ fontSize: 12.5 }} /> */}

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Website</h5>
                    <MenuItemLink to="/homeSections" primaryText="Home Sections" leftIcon={<Widgets />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/heros" primaryText="Home Hero" leftIcon={<DashboardCustomize />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/pages" primaryText="Pages" leftIcon={<Article />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/news" primaryText="News" leftIcon={<Newspaper />} style={{ fontSize: 12.5 }} />

                    <h5 style={{ marginLeft: 10, borderBottom: '1px solid #EEE', fontWeight: 300, paddingBottom: 5, marginBottom: 5 }}>Super Admin</h5>
                    <MenuItemLink to="/systemParameters" primaryText="Parameters" leftIcon={<FileTableBoxMultiple />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/eventLogs" primaryText="Event Logs" leftIcon={<ListAlt />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/verificationLogs" primaryText="V. Logs" leftIcon={<ListAlt />} style={{ fontSize: 12.5 }} />
                    <MenuItemLink to="/paymentLogs" primaryText="Sale Logs" leftIcon={<ListAlt />} style={{ fontSize: 12.5 }} />
                </Menu>
            );
        default:
            return null;
    }
}

export default CustomMenu;