import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    required, ReferenceArrayInput, SelectArrayInput, ImageInput, ImageField, DateInput, FunctionField,
    BooleanInput, TranslatableInputs, AutocompleteArrayInput
} from 'react-admin';

// Libs
import moment from "moment";

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from "../components/form/InputRow";

// Entity
const entity = "groupEvents";

export const GroupEventList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Group Events" exporter={false}>
        <Datagrid>
            <TextField source="name" label={"Name"} />
            <FunctionField source="events" render={(r) => r.events ? r.events.length : 'N/A'} label={"# of Events"} />
            <EditButton />
            {/* <DeleteButton /> */}
        </Datagrid>
    </List>
);

export const GroupEventEdit = (props) => (
    <Edit title="Edit" {...props} redirect={"list"}>
        <SimpleForm redirect={"list"}>
            <h3>Basic Details</h3>
            <TextInput source="name" fullWidth label="Name" validate={required()} />
            <InputRow>
                <DateInput source="startDate" label="Start Date" validate={required()} fullWidth />
                <DateInput source="endDate" label="End Date" validate={required()} fullWidth />
            </InputRow>
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <TextInput source="description" multiline rows={5} label="Description" fullWidth validate={required()} />
            </TranslatableInputs>
            <h3>Actions</h3>
            <BooleanInput source="hasMultipleVenues" label="Does this group event happen in multiple venues?" helperText="Only switch to on (true) if there are multiple venues involved." />
            <h3>Media</h3>
            <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth />
            <ImageInput source="banner" label="Banner Image" accept="image/*" validate={required()} defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <h3>Events</h3>
            <ReferenceArrayInput source="events" reference="events" validate={required()} filter={{isActive: true}}>
                <AutocompleteArrayInput optionText={(r) => `${r.name} (${moment(r.startdate).format('LL, LT')})`} fullWidth label="Select Events" matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} clearOnBlur={false} />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const GroupEventCreate = (props) => (
    <Create title="New" {...props} redirect={"list"}>
        <SimpleForm redirect={"list"}>
            <h3>Basic Details</h3>
            <TextInput source="name" fullWidth label="Name" validate={required()} />
            <InputRow>
                <DateInput source="startDate" label="Start Date" validate={required()} fullWidth />
                <DateInput source="endDate" label="End Date" validate={required()} fullWidth />
            </InputRow>
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <TextInput source="description" multiline rows={5} label="Description" fullWidth validate={required()} />
            </TranslatableInputs>
            <h3>Actions</h3>
            <BooleanInput source="hasMultipleVenues" label="Does this group event happen in multiple venues?" helperText="Only switch to on (true) if there are multiple venues involved." />
            <h3>Media</h3>
            <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />
            <ImageInput source="banner" label="Banner Image" accept="image/*" validate={required()} defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <h3>Events</h3>
            <ReferenceArrayInput source="events" reference="events" validate={required()} filter={{isActive: true}}>
                <AutocompleteArrayInput optionText={(r) => `${r.name} (${moment(r.startdate).format('LL, LT')})`} fullWidth label="Select Events" matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} clearOnBlur={false} />
            </ReferenceArrayInput>

        </SimpleForm>
    </Create>
);
