import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    BooleanInput, SelectInput, RadioButtonGroupInput, FunctionField, TranslatableInputs
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "categories";

// Radio Button Chocies
const choices = [
    { id: 'standardCategory', name: 'Standard Category' },
    { id: 'sessionEventCategory', name: 'Session Event Category' },
];

export const CategoryList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} exporter={false}>
        <Datagrid>
            <TextField source="name.en" label={"Name"} />
            <FunctionField source="active" label={"Active"} render={(r) => r.active ? 'Yes' : 'No'} />
            {/* <FunctionField source="type" label={"Type"} render={(r) => r.type === 'standardCategory' ? 'Standard Category' : 'Session Event Category'} /> */}
            <EditButton />
        </Datagrid>
    </List>
);

export const CategoryEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <BooleanInput source="active" label={"Active"} />
            <TextInput source="id" label={"ID"} disabled />
            <TranslatableInputs locales={['en', 'tr']}>
                <TextInput source="name" label={"Name"} />
            </TranslatableInputs>
            {/* <RadioButtonGroupInput source="type" label="Type" choices={choices} optionText="name" /> */}
        </SimpleForm>
    </Edit>
);

export const CategoryCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <BooleanInput source="active" label={"Active"} disabled hidden />
            <TextInput source="id" label={"ID"} />

            <TranslatableInputs locales={['en', 'tr']}>
                <TextInput source="name" label={"Name"} />
            </TranslatableInputs>

            {/* <RadioButtonGroupInput source="type" label="Type" choices={choices} optionText="name" defaultValue={"standardCategory"} /> */}
        </SimpleForm>
    </Create>
);
