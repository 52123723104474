import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Typography } from '@material-ui/core';
import { useController } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
    imageInput: {
        // width: 'auto'
        // maxWidth: '500px',
    },
    imageContainer: {
        position: 'relative',
    },
    imageContainerImage: {
        maxWidth: '250px',
        // width: '100%',
        // display: 'block',
        // borderRadius: '50%',
        // backgroundColor: '#303030',
        // padding: '5px',
    },
    imageContainerButton: {
        // position: 'absolute',
        // bottom: 0,
        // right: 0,
    },
    hidden: {
        // display: 'none',
    },
}));

export const EditableImage = ({ source, record, width, height, ...props }) => {
    const classes = useStyles();

    const { field, fieldState, formState } = useController({
        name: source,
        defaultValue: {
            [source]: record ? record[source] : '',
        }
    });

    const imageRef = useRef(null);
    const cropRef = useRef(null);

    const [src, setSrc] = useState(null);
    // const [crop, setCrop] = useState({
    //     height: 100,
    //     width: 100,
    //     unit: '%',
    //     aspect: 1,
    // });
    const [crop, setCrop] = useState({
        // unit: '%', // Can be 'px' or '%'
        // x: 25,
        // y: 25,
        // width: 50,
        // height: 50
    })
    const [croppedImageUrl, setCroppedImageUrl] = useState(undefined);

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrc(reader.result);

                setTimeout(() => {
                    field.onChange({
                        title: new Date().toISOString(),
                        src: reader.result,
                        rawFile: e.target.files[0],
                    });
                }, 500);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoaded = (event, t) => {
        imageRef.current = event.target;
        // const { width, height } = event.currentTarget
        // setCrop(centerCrop(width, height, 1));

        const { naturalWidth: width, naturalHeight: height } = event.currentTarget

        const crop = centerCrop(
            makeAspectCrop(
                {
                    // You don't need to pass a complete crop into
                    // makeAspectCrop or centerCrop.
                    unit: '%',
                    width: 100,
                },
                1,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
    };

    const onCropComplete = crop => {
        makeClientCrop(imageRef.current, crop);
    };

    const onCropChange = crop => {
        setCrop(crop);
    }

    const makeClientCrop = async (imageRef, crop) => {
        if (imageRef && crop.width && crop.height) {

            const data = await getCroppedImg(
                imageRef,
                crop,
                'newFile.jpeg',
            );

            setCroppedImageUrl(data.url);

            setTimeout(() => {
                field.onChange({
                    title: new Date().toISOString(),
                    src: data.url,
                    rawFile: data.blob,
                });
            }, 500);
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }

                blob.name = fileName;
                window.URL.revokeObjectURL(croppedImageUrl);

                setCroppedImageUrl(window.URL.createObjectURL(blob));
                resolve({ url: window.URL.createObjectURL(blob), blob: blob });
            }, 'image/jpeg');
        });
    };

    return (
        <div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div className={classes.imageInput}>
                    {src && (
                        <ReactCrop
                            ref={cropRef}
                            crop={crop}
                            locked
                            ruleOfThirds
                            onComplete={onCropComplete}
                            onChange={onCropChange}>
                            <img src={src} onLoad={onImageLoaded} />
                        </ReactCrop>
                    )}
                </div>
                {src ? (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.59 7.91L13.17 12.5L8.59 17.09L10 18.5L16 12.5L10 6.5L8.59 7.91Z"
                                fill="black"
                            />
                        </svg>
                    </div>
                ) : null}

            </div>

            {record && record[source] ? (
                <img
                    src={record[source]['src']}
                    alt={`${record.name}`}
                    className={classes.imageContainerImage}
                />
            ) : (
                <Typography variant="button">Add Image</Typography>
            )}


            <div style={{ display: 'block' }}>

                <input
                    accept="image/*"
                    className={classes.hidden}
                    id="raised-button-file"
                    type="file"
                    onChange={onSelectFile}
                />

            </div>

            {/* <div>
                {croppedImageUrl ? (
                    // link to preview the cropped image in a modal
                    <React.Fragment>
                        <img src={croppedImageUrl} 
                        alt="Crop"
                            style={{
                                width: crop.width,
                                height: crop.height,
                            }} />
                        <div>
                            <div style={{ marginTop: 20 }}>
                                <button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setCroppedImageUrl(undefined);
                                        setSrc(null);
                                    }}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className={classes.imageContainer}>
                        {record && record[source] ? (
                            <img
                                src={record[source]['src']}
                                alt={`${record.name}`}
                                className={classes.imageContainerImage}
                            />
                        ) : (
                            <Typography variant="button">Add Image</Typography>
                        )}
                        <div className={classes.imageContainerButton}>
                            <input
                                accept="image/*"
                                className={classes.hidden}
                                id="raised-button-file"
                                type="file"
                                onChange={onSelectFile}
                            />
                        </div>
                    </div>
                )}
            </div> */}

        </div>
    );
};