import React, { useState, Fragment } from 'react';

// Firebase
import { db } from "../../database/firebase";
import { collection, query, where, getCountFromServer } from "firebase/firestore";

// UI
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

// RA
import { DeleteButton, Button } from 'react-admin';


// Conditional Delete Button
// Delete only if there are no sales associated with the event
// If so, show an alert and don't proceed.

const ConditionalDeleteButton = ({ record }) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [salesSize, setSalesSize] = useState(0);

    const getSales = async () => {
        const coll = collection(db, "sales");
        const q = query(coll, where("event.id", "==", record.id));
        const snapshot = await getCountFromServer(q);
        // console.log('count: ', snapshot.data().count);
        setSalesSize(snapshot.data().count);
    }

    if (!record) return null;

    return (
        <React.Fragment>
            <Button label="Delete" onClick={async () => {
                setOpen(true);
                setIsLoading(true);
                await getSales();
                setIsLoading(false);
            }} />

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Event
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            isLoading ? (
                                <span>Loading...</span>
                            ) : (
                                salesSize && salesSize > 0 ? (
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>Please note that there are sales associated with this event. You can not delete this event.</span>
                                ) : (
                                    <span>There are no sales associated with this event, it can be deleted. Are you sure you want to delete this event?</span>
                                )
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button label="Cancel" onClick={() => setOpen(false)} />
                    {
                        isLoading ? (
                            null
                        ) : (
                            salesSize && salesSize > 0 ? (
                                null
                            ) : (
                                <DeleteButton record={record} />
                            )
                        )
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ConditionalDeleteButton;