import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, FunctionField, EditButton,
    ReferenceInput, ReferenceField, AutocompleteInput, TextInput
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "saleAgents";

export const SaleAgentList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Sale Agents">
        <Datagrid>
            <ReferenceField source="user" reference="users" label="User" linkType="show">
                <FunctionField render={(r) => `${r.name} ${r.surname}`} />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

export const SaleAgentEdit = (props) => (
    <Edit title="Edit" {...props} redirect="list">
        <SimpleForm redirect={`/${entity}`}>
            <ReferenceInput source="user" reference="users">
                <AutocompleteInput optionText="email" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} />
            </ReferenceInput>
            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} />
        </SimpleForm>
    </Edit>
);

export const SaleAgentCreate = (props) => (
    <Create title="New" {...props} redirect="list">
        <SimpleForm redirect={`/${entity}`}>
            <ReferenceInput source="user" reference="users">
                <AutocompleteInput optionText="email" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} />
            </ReferenceInput>
            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} />
        </SimpleForm>
    </Create>
);
