import React from "react";
import {
    Edit, Create, TextField,
    required, FormDataConsumer, ReferenceInput, ArrayInput, SimpleFormIterator, NumberInput,
    TextInput, DateTimeInput, SelectInput, ImageInput, ImageField, RadioButtonGroupInput, ReferenceField,
    FunctionField, CheckboxGroupInput, TranslatableInputs, BooleanInput,
    Show, usePermissions, SelectArrayInput, ReferenceArrayInput, AutocompleteInput,
    TabbedForm, FormTab, useEditContext, DateInput, useDataProvider, Toolbar, SaveButton, Button
} from 'react-admin';

// Components
import InputRow from '../../components/form/InputRow';
import SeatingPlanEditor from "../../components/seating/SeatingPlanEditor";
import EventStockReport from "../../components/events/EventStockReport";
// import EventContract from "../../components/events/EventContract";

// Libs
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';

// Utils
import { EditableImage } from "../../components/form/EditableImageField";

// UI
import { Box } from '@mui/material';

// Constants
const ticketGroupStatuses = [{ id: 0, name: "On Sale" }, { id: 1, name: "Sold Out" }, { id: 2, name: "Hidden" }];
const dressCodes = [{ id: 1, name: "Casual" }, { id: 2, name: "Come as you are" }, { id: 3, name: "Elegant" }, { id: 4, name: "Smart Casual" }, { id: 5, name: "No Dress Code" }];
const couplesRules = [{ id: 1, name: "No Rules" }, { id: 2, name: "Men have to be with women" }];


/**
 * 
 * @returns Event Edit
 */
export const EventEdit = (props) => {
    const { permissions } = usePermissions();
    if (!permissions) return;

    return (
        <Edit title="Edit" {...props} actions={false}>
            <EventEditForm permissions={permissions} />
        </Edit>
    )
}
const EventEditForm = ({ permissions }, ...props) => {
    const { record } = useEditContext();
    const dataProvider = useDataProvider();

    return (
        <React.Fragment>

            <h2>Edit: {record.name} - {moment(record.startdate).format('LL LT')}</h2>
            {record.isSessionEvent && (
                <span style={{ fontSize: 12, color: 'white', backgroundColor: '#FAC898', padding: 5, marginBottom: 10 }}>
                    Warning! This is a session event of the main event:
                    <ReferenceField source="mainEvent" reference="events">
                        <TextField source="name" />
                    </ReferenceField>
                </span>
            )}

            <TabbedForm warnWhenUnsavedChanges toolbar={<CCreateToolbar />}>

                <FormTab label="Basic Details">
                    <h3>Basic Details</h3>

                    <TextInput source="name" label="Event Name" fullWidth validate={required()} className="autoCapitalize" />

                    <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }}>
                        <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                            if (formData.venue && formData.venue.length > 0) {
                                return (
                                    <ReferenceInput source="venue" reference="venues" filter={{ id: formData.venue }} validate={required()} label="Venue City">
                                        <SelectInput source="city" optionText="city" fullWidth disabled />
                                    </ReferenceInput>
                                )
                            }
                        }}
                    </FormDataConsumer>


                    <div>
                        {moment(record.startdate).isSame(moment().add(1, 'days'), 'day') && moment(record.enddate).isSame(moment().add(2, 'days'), 'day') && (
                            <span style={{ fontSize: 12, color: 'white', backgroundColor: '#f6a14f', padding: 5, marginBottom: 10 }}>
                                Warning! This is a cloned event. Please pay attention to the dates as they are set to tomorrow and the day after tomorrow.
                            </span>
                        )}
                    </div>

                    <InputRow>
                        {/* <DateTimeInput source="startdate" fullWidth validate={required()} disabled={permissions.crole && permissions.crole !== "admin"} label="Starts" /> */}
                        {/* <DateTimeInput source="enddate" fullWidth validate={required()} disabled={permissions.crole && permissions.crole !== "admin"} label="Ends" /> */}
                        <DateTimeInput source="startdate" fullWidth validate={required()} label="Starts" />
                        <DateTimeInput source="enddate" fullWidth validate={required()} label="Ends" />
                    </InputRow>

                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={[]}>
                        <SelectArrayInput optionText={"name"} fullWidth disabled={permissions.crole && permissions.crole === "organisationCompanyManager"} defaultValue={[]} />
                    </ReferenceArrayInput>

                    <h3>Category</h3>
                    <ReferenceInput source="category" reference="categories">
                        <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                            return (
                                <ReferenceArrayInput source="subcategories" reference="subcategories" label={"Sub Categories"} filter={filter} sort={{ field: 'name.en', order: 'ASC' }}>
                                    <SelectArrayInput optionText="name.en" fullWidth validate={required()} label={"Sub Categories"} />
                                </ReferenceArrayInput>
                            )
                        }}
                    </FormDataConsumer>

                    <h3>Payment Methods</h3>
                    <CheckboxGroupInput source="paymentMethods" choices={[
                        { id: 'cc', name: 'Credit Card' },
                        { id: 'doorcash', name: 'Cash At Door' }
                    ]} validate={required()} />
                </FormTab>

                <FormTab label="More Details">

                    <h3>Event Description</h3>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="description" label="Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    </TranslatableInputs>

                    <h3>Event Rules</h3>

                    <h4>Things to know</h4>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="details" label="Event Rules" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    </TranslatableInputs>

                    <h4>Rule Chips</h4>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Rules that are left empty won't appear on the front-end website.</span>
                    <TextInput source="rules.age" label="Age Rule" fullWidth defaultValue={null} />
                    <InputRow>
                        <SelectInput source="rules.dress" choices={dressCodes} fullWidth defaultValue={[]} />
                        <SelectInput source="rules.couples" choices={couplesRules} fullWidth defaultValue={[]} />
                    </InputRow>
                </FormTab>

                <FormTab label="Visuals">
                    <h3>Visual / Poster</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images that are of size 500 x 500 should be uploaded.</span>

                    {/* <ImageInput source="banner" label="Event Visual" accept="image/*" defaultValue={null} >
                        <ImageField source="src" title="title" />
                    </ImageInput> */}

                    <EditableImage record={record} source="banner" height={500} width={500} {...props} label="Image label" />

                    <h3>Event Video</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
                    <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />

                    <h3>Venue Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        If there is a specific layout for this event. If not default layout for the venue will be used.
                    </span>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="venueLayout" label=" " accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>

                </FormTab>

                <FormTab label="Products/Tickets">

                    <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Special Deal <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.deal" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, true, scopedFormData, true);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Reservation <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.guestlist" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, true, scopedFormData, true);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>

                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>General <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.general" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, true, scopedFormData, true);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>

                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>VIP List <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.vip" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, true, scopedFormData, true);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                    </Box>


                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Extras <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.extras" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, true, scopedFormData, true);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>

                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Invitations <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Can create invitations for guests to attend the event.</span>
                                <ArrayInput source="tickets.invitation" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, true, scopedFormData, true);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>

                    </Box>


                </FormTab>

                <FormTab label="Layout" disabled>
                    <h3>Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        Venue should be selected first to see the available layouts.
                    </span>


                    {/* A button to "reset" the layout. This will just delete the layoutJSON field of the record and reload the page. */}
                    <Button variant="contained" color="primary" style={{ marginBottom: 20 }} onClick={async () => {
                        if (window.confirm("Are you sure you want to reset the layout?")) {
                            await dataProvider.update('events', { id: record.id, data: { layout: null, layoutJSON: null } })

                            // reload the page
                            window.location.reload();
                        }
                    }} label="Reset Layout" />

                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.venue && formData.venue.length > 0 ? { venue: formData.venue, layoutType: 'area' } : { layoutType: 'area' };
                            let toReturn = null;

                            if (formData.venue && !formData.layout) {
                                toReturn = (
                                    <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                        <SelectInput optionText={"name"} fullWidth />
                                    </ReferenceInput>
                                )
                            } else if (formData.venue && formData.layout && formData.layoutJSON) {
                                toReturn = (
                                    <React.Fragment>
                                        <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                            <SelectInput optionText={"name"} fullWidth />
                                        </ReferenceInput>
                                        <SeatingPlanEditor fullLayout record={formData.layoutJSON} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} layoutType="area" />
                                    </React.Fragment>
                                );
                            } else if (formData.venue && formData.layout && !formData.layoutJSON) {
                                toReturn = (
                                    <React.Fragment>
                                        <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                            <SelectInput optionText={"name"} fullWidth />
                                        </ReferenceInput>
                                        <SeatingPlanEditor record={formData.layout} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} layoutType="area" />
                                    </React.Fragment>
                                );
                            }

                            return toReturn;
                        }}
                    </FormDataConsumer>

                </FormTab>

                <FormTab label="Coupons">
                    <div>
                        {moment(record.startdate).isSame(moment().add(1, 'days'), 'day') && moment(record.enddate).isSame(moment().add(2, 'days'), 'day') && ( // record.isCloned
                            <span style={{ fontSize: 12, color: 'white', backgroundColor: '#f6a14f', padding: 5, marginBottom: 10 }}>
                                Warning! This is a cloned event. Please pay attention to the active dates for the coupons as they might be set to tomorrow. If not please ignore this warning.
                            </span>
                        )}
                    </div>
                    <h3>Coupons</h3>
                    <ArrayInput source="coupons" defaultValue={[]} label=" ">
                        <SimpleFormIterator disableReordering disableRemove={!record.isClone} className="couponsIterator">

                            {/* Disable changing of the discount amount and code if used > 0 */}
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                        <React.Fragment>
                                            <TextInput source={getSource("code")} label={"Coupon Code"} validate={required()} fullWidth
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the coupon code for a USED coupon, you can do so by creating a new one' : ''}
                                                disabled={scopedFormData.used && scopedFormData.used > 0}
                                            />
                                            <NumberInput
                                                onWheel={event => { event.target.blur(); }} source={getSource("discount")} type="number" label={"Discount (TL)"} min={0}
                                                validate={required()} fullWidth disabled={scopedFormData.used && scopedFormData.used > 0}
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the discount amount for a USED coupon, you can do so by creating a new one' : ''}
                                            />
                                        </React.Fragment>
                                    );
                                }}
                            </FormDataConsumer>

                            <NumberInput onWheel={event => { event.target.blur(); }} source="stock" type="number" label={"Stock"} validate={required()} fullWidth />
                            <NumberInput onWheel={event => { event.target.blur(); }} source="used" type="number" label={"Used"} fullWidth disabled />
                            <DateInput source="activeUntil" label={"Active Until"} validate={required()} fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Fees">
                    <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)" helperText="Service fee to cover extra operational cost" defaultValue={0} />
                    <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee" min={0} fullWidth label="Event Commission Fee (%)" defaultValue={0} />
                    <BooleanInput source="isCommissionExtra" defaultValue={false} label="Is commission extra (added to ticket prices)?" helperText="Please note that the prices for the event will change if this is turned on." />
                </FormTab>

                <FormTab label="Settings">
                    <h3>Toggle Settings</h3>
                    <BooleanInput source="isActive" label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." />
                    <BooleanInput source="isVerified" label="Verified Event?" helperText="Is event verified by the admin?" disabled={permissions.crole && permissions.crole !== "admin"} />

                    <h3>Extra Fields</h3>
                    <FunctionField source="id" label="Preview Link" render={(r) => {
                        return (
                            <div style={{ fontSize: 12 }}>
                                Event Preview Link: &nbsp;
                                <a href={"https://www.gisekibris.com/etkinlikler/".concat(r.id).toString().concat('?mode=preview')} target="_blank">
                                    {
                                        r.name
                                    }
                                </a>
                            </div>
                        )
                    }} />

                    {/* CANCEL EVENT SECTION */}
                    {permissions.crole && permissions.crole === "admin" && (
                        <React.Fragment>

                            <h3>Cancel Event</h3>

                            <span style={{ fontSize: 14, marginTop: -10, marginBottom: 10, color: 'red' }}>
                                This action will cancel the event and send notification to all the ticket holders.
                            </span>

                            <Button variant="contained" color="primary" style={{ backgroundColor: 'red' }} onClick={async () => {
                                if (window.confirm("Are you sure you want to cancel this event?")) {
                                    await dataProvider.update('events', { id: record.id, data: { isCancelled: true, isActive: false, isVerified: false } });
                                    window.location.reload();
                                }
                            }} label="Cancel Event" />

                        </React.Fragment>
                    )}
                </FormTab>
            </TabbedForm>

        </React.Fragment>

    );


}


/**
 * 
 * @returns Event Create
 */

const CCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            alwaysEnable
            transform={data => {
                const seatingPlan = localStorage.getItem('seatingPlan');
                if (seatingPlan) localStorage.removeItem('seatingPlan');
                return { ...data, notify: true, layoutJSON: seatingPlan ?? null };
            }}
            type="button"
        />
    </Toolbar>
);

export const EventCreate = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;
    return (
        <Create title="Create New Event" {...props} redirect={"list"}>
            <EventCreateForm permissions={permissions} />
        </Create>
    );
}
const EventCreateForm = ({ permissions }, ...props) => {
    const dataProvider = useDataProvider();

    // fetch system parameters and get "system" doc to get the default values for the fees

    const [systemParams, setSystemParams] = React.useState(null);

    React.useEffect(() => {
        dataProvider.getOne('systemParameters', { id: 'system' })
            .then(res => {
                setSystemParams(res.data);
            })
    }, []);

    if (!systemParams) return "Loading...";

    return (
        <React.Fragment>
            <h2>Create New Event</h2>
            <TabbedForm warnWhenUnsavedChanges id="eventForm" toolbar={<CCreateToolbar />}>
                <FormTab label="Basic Details">
                    <h3>Basic Details</h3>
                    <TextInput source="name" label="Event Name" fullWidth validate={required()} className="autoCapitalize" />

                    <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                        <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()} />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {
                            if (formData.venue && formData.venue.length > 0) {
                                return (
                                    <ReferenceInput source="venue" reference="venues" filter={{ id: formData.venue }} validate={required()} label="Venue City">
                                        <SelectInput source="city" optionText="city" fullWidth disabled />
                                    </ReferenceInput>
                                )
                            }
                        }}
                    </FormDataConsumer>

                    <InputRow>
                        <DateTimeInput source="startdate" fullWidth validate={required()} label="Starts" />
                        <DateTimeInput source="enddate" fullWidth validate={required()} label="Ends" />
                    </InputRow>

                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={[]}
                        sort={{
                            field: 'name',
                            order: 'ASC'
                        }} perPage={50}>
                        <SelectArrayInput optionText={"name"} fullWidth disabled={permissions.crole && permissions.crole === "organisationCompanyManager"} value={(permissions.crole && permissions.crole === "organisationCompanyManager") && [permissions.rid]} defaultValue={(permissions.crole && permissions.crole === "organisationCompanyManager") ? [permissions.rid] : []} />
                    </ReferenceArrayInput>

                    <h3>Category</h3>
                    <ReferenceInput source="category" reference="categories" sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'standardCategory' }}>
                        <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                            return (
                                <ReferenceArrayInput source="subcategories" reference="subcategories" label={"Sub Categories"} filter={filter} sort={{ field: 'name.en', order: 'ASC' }}>
                                    <SelectArrayInput optionText="name.en" fullWidth validate={required()} label={"Sub Categories"} />
                                </ReferenceArrayInput>
                            )
                        }}
                    </FormDataConsumer>
                    <h3>Payment Methods</h3>
                    {/* <h3>Payment Methods <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h3> */}
                    <CheckboxGroupInput source="paymentMethods"
                        defaultChecked={['cc']}
                        defaultValue={['cc']}
                        choices={[
                            { id: 'cc', name: 'Credit Card' },
                            // { id: 'doorcash', name: 'Cash At Door' }
                        ]} validate={required()} />
                </FormTab>
                <FormTab label="More Details">
                    <h3>Event Description <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h3>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="description" label="Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    </TranslatableInputs>

                    <h3>Event Rules</h3>

                    <h4>Things to know <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h4>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="details" label="Event Rules" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    </TranslatableInputs>

                    <h4>Rule Chips</h4>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Rules that are left empty won't appear on the front-end website.</span>
                    <TextInput source="rules.age" label="Age Rule" fullWidth defaultValue={null} />
                    <InputRow>
                        <SelectInput source="rules.dress" choices={dressCodes} fullWidth defaultValue={[]} />
                        <SelectInput source="rules.couples" choices={couplesRules} fullWidth defaultValue={[]} />
                    </InputRow>
                </FormTab>
                <FormTab label="Visuals">
                    <h3>Visual / Poster</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images that are of size 500 x 500 should be uploaded.</span>
                    <EditableImage source="banner" {...props} />
                    <h3>Event Video</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
                    <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />

                    <h3>Venue Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        If there is a specific layout for this event. If not default layout for the venue will be used.
                    </span>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="venueLayout" label=" " accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Products/Tickets">

                    <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Special Deal <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.deal" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, false, scopedFormData);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Reservation <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.guestlist" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, false, scopedFormData);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>General <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.general" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, false, scopedFormData);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>

                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>VIP List <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.vip" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, false, scopedFormData);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                    </Box>


                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Extras <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <ArrayInput source="tickets.extras" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, false, scopedFormData);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <div style={{ border: '1px dashed #000', padding: 5 }}>
                                <h3>Invitations <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                                <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Can create invitations for guests to attend the event.</span>
                                <ArrayInput source="tickets.invitation" defaultValue={[]}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ formData, scopedFormData, getSource, ...rest }) => {
                                                return productForm(getSource, false, scopedFormData);
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        </Box>
                    </Box>


                </FormTab>

                <FormTab label="Layout">
                    <h3>Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        Venue should be selected first to see the available layouts.
                    </span>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.venue && formData.venue.length > 0 ? { venue: formData.venue, layoutType: 'area' } : { layoutType: 'area' };
                            let toReturn = null;

                            if (formData.venue && !formData.layout) {
                                toReturn = (
                                    <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                        <SelectInput optionText={"name"} fullWidth defaultValue={null} />
                                    </ReferenceInput>
                                )
                            } else if (formData.venue && formData.layout) {
                                toReturn = (
                                    <React.Fragment>
                                        <ReferenceInput source="layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                            <SelectInput optionText={"name"} fullWidth defaultValue={null} />
                                        </ReferenceInput>
                                        <SeatingPlanEditor record={formData.layout} isAssignMode={true} tickets={formData.tickets} dataProvider={dataProvider} isStandardEvent={true} layoutType={'area'} />
                                    </React.Fragment>
                                );
                            }

                            return toReturn;
                        }}
                    </FormDataConsumer>

                </FormTab>
                <FormTab label="Coupons">
                    <h3>Coupons</h3>
                    <ArrayInput source="coupons" defaultValue={[]} label=" ">
                        <SimpleFormIterator disableReordering disableRemove className="couponsIterator">

                            {/* Disable changing of the discount amount and code if used > 0 */}
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                        <React.Fragment>
                                            <TextInput source={getSource("code")} label={"Coupon Code"} validate={required()} fullWidth
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the coupon code for a USED coupon, you can do so by creating a new one' : ''}
                                                disabled={scopedFormData.used && scopedFormData.used > 0}
                                            />
                                            <NumberInput
                                                onWheel={event => { event.target.blur(); }} source={getSource("discount")} type="number" label={"Discount (TL)"} min={0}
                                                validate={required()} fullWidth disabled={scopedFormData.used && scopedFormData.used > 0}
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the discount amount for a USED coupon, you can do so by creating a new one' : ''}
                                            />
                                        </React.Fragment>
                                    );
                                }}
                            </FormDataConsumer>

                            <NumberInput onWheel={event => { event.target.blur(); }} source="stock" type="number" label={"Stock"} validate={required()} fullWidth />
                            <NumberInput onWheel={event => { event.target.blur(); }} source="used" type="number" label={"Used"} fullWidth disabled />
                            <DateInput source="activeUntil" label={"Active Until"} validate={required()} fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Fees">
                    {
                        systemParams && systemParams.variables ? (
                            <React.Fragment>
                                <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)"
                                    helperText="Service fee to cover extra operational cost"
                                    defaultValue={systemParams.variables.defaultServiceFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee"
                                    min={0} fullWidth label="Event Commission Fee (%)"
                                    defaultValue={systemParams.variables.defaultCommissionFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <BooleanInput source="isCommissionExtra" defaultValue={false} label={`Is ${systemParams.variables.defaultCommissionFee}% commission extra (added to ticket prices)?`} helperText="Please note that the prices for the event will change if this is turned on." />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)" helperText="Service fee to cover extra operational cost" defaultValue={0} />
                                <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee" min={0} fullWidth label="Event Commission Fee (%)" defaultValue={10} />
                                <BooleanInput source="isCommissionExtra" defaultValue={false} label="Is 10% commission extra (added to ticket prices)?" helperText="Please note that the prices for the event will change if this is turned on." />
                            </React.Fragment>
                        )
                    }
                </FormTab>
                {/* <FormTab label="Contract">
                    <h3>Contract</h3>
                    <div style={{ whiteSpace: 'pre' }}>
                        <FormDataConsumer>
                            {({ formData, getSource, ...rest }) => {
                                if (
                                    !formData.name
                                    || !formData.startdate
                                    || !formData.venue
                                )
                                    return "Please fill the required fields to see the contract."
                                else {
                                    return (
                                        <React.Fragment>
                                            <div style={{ fontSize: 14, marginBottom: 10 }}>
                                                Please read the contract carefully and accept it to continue.
                                            </div>
                                            <EventContract dp={dataProvider} event={formData} />
                                            <BooleanInput source="contract" label="I accept the contract" validate={required()} />
                                        </React.Fragment>
                                    )
                                }
                            }}
                        </FormDataConsumer>
                    </div>
                </FormTab> */}
                <FormTab label="Settings">
                    <BooleanInput source="isActive" value={true} checked={true} label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." hidden={true} disabled={true} />
                    <BooleanInput source="isVerified" value={(permissions.crole && permissions.crole === "admin")} checked={(permissions.crole && permissions.crole === "admin")} label="Verified Event?" helperText="Is event verified by the admin?" hidden={true} disabled={true} />
                </FormTab>

            </TabbedForm>
        </React.Fragment>
    )
}

/**
 * 
 * @returns Event Show
 */
export const EventShow = () => {
    return (
        <Show title={"Stock Report"} style={{ padding: 20 }}>
            <EventStockReport />
        </Show>
    );
}

// Show Component Exporter
// Exports Sales Data of the event to CSV
const showExporter = (record) => {
    let data = [];
    const tickets = record.tickets;

    let totalTotalSale = 0;

    tickets.deal.map(r => {
        data.push({
            "Category": "Promotion",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        });

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.extras.map(r => {
        data.push({
            "Category": "Extras",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        })

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.general.map(r => {
        data.push({
            "Category": "General",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        })

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.guestlist.map(r => {
        data.push({
            "Category": "Reservation",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        });

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    tickets.vip.map(r => {
        data.push({
            "Category": "VIP",
            "Title": r.title.tr,
            "Price": r.price,
            "Stock": r.stock,
            "Sold": r.sold,
            "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                parseInt(r.stock) - parseInt(r.sold)
            ) : 'N/A',
            "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
        });

        if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
        return true;
    });

    if (tickets.invitation) {
        tickets.invitation.map(r => {
            data.push({
                "Category": "Invitation",
                "Title": r.title.tr,
                "Price": r.price,
                "Stock": r.stock,
                "Sold": r.sold,
                "Remaining": (r.stock && parseInt(r.stock) && r.sold && parseInt(r.sold)) ? (
                    parseInt(r.stock) - parseInt(r.sold)
                ) : 'N/A',
                "Total Sale": (r.sold && r.sold > 0 ? (parseInt(r.sold) * r.price).toString().concat(' TL') : '0')
            });

            if (r.sold && r.sold > 0 && r.price && r.price > 0) totalTotalSale = totalTotalSale + (parseInt(r.sold) * r.price);
            return true;
        });
    }

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        arrayOfArrayCsv.unshift([`${record.name} - ${moment(record.startdate).format("LL")}`]);
        arrayOfArrayCsv.push(['', '', '', '', '', '', `${totalTotalSale.toString().concat(' TL')}`]);

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-stock-${slugify(record.name)}-export--${moment().format('LL-LT')}.xlsx`);
    });
}

// Tools
const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

const productForm = (getSource, showSold, scopedFormData, isEditForm) => {
    return (
        <React.Fragment>

            {/* Auto generate ticket ID on add */}
            <TextInput source={getSource("id")} label={"Ticket ID"} validate={required()} fullWidth disabled defaultValue={genereateTicketID()} />

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source={getSource("title.tr")} label={"Turkish Title"} validate={required()} fullWidth className="autoCapitalize" autoComplete="off" />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source={getSource("title.en")} label={"English Title"} validate={required()} fullWidth className="autoCapitalize" autoComplete="off" />
                </Box>
            </Box>
            <Box>
                {/* <TextInput source={getSource("description.tr")} label={"Turkish Description"} validate={required()} fullWidth multiline rows={3} /> */}
                <RichTextInput source={getSource("description.tr")} label="Turkish Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                {/* <TextInput source={getSource("description.en")} label={"English Description"} validate={required()} fullWidth multiline rows={3} /> */}
                <RichTextInput source={getSource("description.en")} label="English Description" validate={required()} fullWidth multiline rows={3} toolbar={<RichTextInputToolbar size="small" />} />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput
                        onWheel={event => { event.target.blur(); }} source={getSource("price")} type="number" label={"Price"} min={0}
                        validate={required()} fullWidth disabled={isEditForm && scopedFormData.sold && scopedFormData.sold > 0}
                        helperText={isEditForm ? 'If you need to change the price of a product, you can do so by creating a new product and hiding the old one.' : ''}
                    />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("stock")} type="number" label={"Stock"} validate={required()} fullWidth />
                </Box>
            </Box>
            {showSold && <NumberInput source={getSource("sold")} type="number" label={"Total Sold"} disabled fullWidth />}

            <BooleanInput source={getSource("hasDownPayment")} label={"Has Down Payment?"} defaultChecked={false} defaultValue={false} />

            {
                scopedFormData.hasDownPayment && (
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("downPaymentAmount")} type="number" label={"Down Payment Price"} validate={required()} min={0} fullWidth />
                )
            }

            <BooleanInput source={getSource("hasBasketLimit")} label={"Has Basket Limit?"} defaultChecked={false} defaultValue={false} />

            {
                scopedFormData.hasBasketLimit && (
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("basketLimit")} type="number" label={"Basket Limit"} validate={required()} fullWidth />
                )
            }

            <RadioButtonGroupInput source={getSource("status")} label={"Status"} choices={ticketGroupStatuses} defaultValue={0} validate={required()} />
        </React.Fragment>
    )
}

const genereateTicketID = () => {
    // Capital letters and numbers, length 8
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 8).toUpperCase();
}