import { CreateButton } from 'react-admin'

const EmptyList = props => (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: 600, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <div>
            There are no {props.entity} available.
        </div>
        {
            !props.noCreate && (
                <div style={{ marginTop: 20 }}>
                    <CreateButton 
                    // basePath={`/${props.entity}`} 
                    />
                </div>
            )
        }
    </div>
);

export default EmptyList;