import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, DeleteButton, TextInput, NumberField, NumberInput,
    required, BooleanInput, ImageInput, ImageField, ReferenceInput, SelectInput, FormDataConsumer, AutocompleteInput
} from 'react-admin';

// Libs
import moment from "moment";

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from '../components/form/InputRow';

// Entity
const entity = "homeSections";

export const HomeSectionList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Home Sections" sort={{ field: 'order', order: 'ASC' }}>
        <Datagrid>
            <TextField source="title.en" label={"Title (English)"} />
            <NumberField source="order" label={"Order"} />
            <EditButton />
            <DeleteButton basePath={`/${entity}`} />
        </Datagrid>
    </List>
);

export const HomeSectionEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Main Details</h3>
            <NumberInput source="order" label={"Order"} validate={required()} fullWidth />
            <TextInput source="title.tr" label={"Title TR"} validate={required()} fullWidth />
            <TextInput source="title.en" label={"Title EN"} validate={required()} fullWidth />
            {/* <RichTextInput source="description.tr" validate={required()} fullWidth label={"Description TR"} />
            <RichTextInput source="description.en" validate={required()} fullWidth label={"Description EN"} /> */}
            <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <h3>Categories</h3>
            <InputRow>
                <ReferenceInput source="category" reference="categories">
                    <SelectInput optionText={"name.en"} fullWidth />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                        return (
                            <ReferenceInput source="subcategory" reference="subcategories" label={"Sub Category"} filter={filter}>
                                <SelectInput optionText="name.en" fullWidth />
                            </ReferenceInput>
                        )
                    }}
                </FormDataConsumer>
            </InputRow>

            <h3>Content</h3>
            <BooleanInput label="Show upcoming events of the category?" source="fetchUpcoming" />

            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (formData.fetchUpcoming === false) && (
                        <React.Fragment>

                            <h3>Design</h3>
                            <BooleanInput label="Use carousel design?" source="isCarousel" />

                            <h3>Select Events</h3>

                            <h3>Event 1</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>

                                <ReferenceInput source="events.event1.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                {/* <ReferenceInput source="events.event1.id" reference="events" perPage={50}>
                                    <SelectInput optionText="name" fullWidth label="Select Event" />
                                </ReferenceInput> */}
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event1.id) && (
                                            <ReferenceInput source="events.event1.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 2</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                            <ReferenceInput source="events.event2.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event2.id) && (
                                            <ReferenceInput source="events.event2.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 3</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                            <ReferenceInput source="events.event3.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event3.id) && (
                                            <ReferenceInput source="events.event3.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 4</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                            <ReferenceInput source="events.event4.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event4.id) && (
                                            <ReferenceInput source="events.event4.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>
                        </React.Fragment>
                    )
                }}
            </FormDataConsumer>

        </SimpleForm>
    </Edit>
);

export const HomeSectionCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Main Details</h3>
            <NumberInput source="order" label={"Order"} validate={required()} fullWidth />
            <TextInput source="title.tr" label={"Title TR"} validate={required()} fullWidth />
            <TextInput source="title.en" label={"Title EN"} validate={required()} fullWidth />
            {/* <RichTextInput source="description.tr" validate={required()} fullWidth label={"Description TR"} />
            <RichTextInput source="description.en" validate={required()} fullWidth label={"Description EN"} /> */}
            <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <h3>Categories</h3>
            <InputRow>
                <ReferenceInput source="category" reference="categories">
                    <SelectInput optionText={"name.en"} fullWidth />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                        return (
                            <ReferenceInput source="subcategory" reference="subcategories" label={"Sub Category"} filter={filter}>
                                <SelectInput optionText="name.en" fullWidth />
                            </ReferenceInput>
                        )
                    }}
                </FormDataConsumer>
            </InputRow>

            <h3>Content</h3>
            <BooleanInput label="Show upcoming events of the category?" source="fetchUpcoming" defaultValue={true} defaultChecked />

            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.fetchUpcoming === false && (
                        <React.Fragment>

                            <h3>Design</h3>
                            <BooleanInput label="Use carousel design?" source="isCarousel" />

                            <h3>Select Events</h3>

                            <h3>Event 1</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event1.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event1.id) && (
                                            <ReferenceInput source="events.event1.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 2</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                            <ReferenceInput source="events.event2.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event2.id) && (
                                            <ReferenceInput source="events.event2.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 3</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                            <ReferenceInput source="events.event3.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event3.id) && (
                                            <ReferenceInput source="events.event3.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 4</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                            <ReferenceInput source="events.event4.id" reference="events" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event4.id) && (
                                            <ReferenceInput source="events.event4.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>
                        </React.Fragment>
                    )
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
