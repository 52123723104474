import moment from "moment";
import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, 
    SearchInput,
    TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "affiliates";

const filters = [
    <TextInput source="code" alwaysOn placeholder="AF Code" label="Search AF Code" autoComplete="off" />,
]

export const AffiliatesList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Affiliates" exporter={false} filters={filters}>
        <Datagrid>
            <TextField source="name" label={"Name"} />
            <TextField source="code" label={"Affiliate Code"} />
            <EditButton />
        </Datagrid>
    </List>
);

export const AffiliatesEdit = (props) => {
    return (
        <Edit title="Edit" {...props}>
            <SimpleForm redirect={`/${entity}`}>
                <TextInput source="name" fullWidth />
                <ReferenceInput source="user" reference="users" defaultValue={null}>
                    <AutocompleteInput optionText={(r) => r.email + (r.crole ? ` (${r.crole})` : '')} fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} defaultValue={null}/>
                </ReferenceInput>
                <TextInput source="code" fullWidth disabled />
            </SimpleForm>
        </Edit>
    );
}

export const AffiliatesCreate = (props) => {
    let code = "AF" + moment().format('x')
    return (
        <Create title="Create" {...props} redirect={"list"}>
            <SimpleForm redirect={`/${entity}`}>
                <TextInput source="name" fullWidth />
                <TextInput source="code" defaultValue={code} value={code} fullWidth disabled />
                <ReferenceInput source="user" reference="users" defaultValue={null}>
                    <AutocompleteInput optionText={(r) => r.email + (r.crole ? ` (${r.crole})` : '')} fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} defaultValue={null} />
                </ReferenceInput>
                <div style={{ visibility: 'hidden' }}>
                    <TextInput source="id" fullWidth disabled defaultValue={code} value={code} />
                </div>
            </SimpleForm>
        </Create>
    );

}