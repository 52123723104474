import React, { useEffect, useState } from "react";
import { useRecordContext, useNotify, usePermissions } from "react-admin";

// Libs
import moment from "moment";

// Firebase
import { db } from "../../database/firebase";
import { collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";

// DataGrid
import { DataGridPremium, GridToolbarContainer } from '@mui/x-data-grid-premium';

const D_COLUMNS = [
    {
        field: 'title', headerName: 'Title', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.category ? params.row.category.toUpperCase() : ""}</b>;
        }
    },
    {
        field: 'category', headerName: 'Category', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.category ? params.row.category.toUpperCase() : "";
        }
    },
    {
        field: 'price', headerName: 'Price', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.price ? params.row.price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }
    },
    {
        field: 'original-stock', headerName: 'Total Stock', width: 100, filterable: false, valueGetter: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock + params.row.sold;
        }
    },

    {
        field: 'sold', headerName: 'Sold', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.sold}</b>;
            return params.row.sold ? params.row.sold : 0;
        }
    },
    { field: 'stock', headerName: 'Remaining Stock', width: 100, filterable: false },
    {
        field: 'totalSale', headerName: 'Total Sale', width: 100, filterable: false, valueGetter: (params) => {

            let totalSale = params.row.price * params.row.sold;
            if (params.row.id === 'total') totalSale = params.row.totalSale;

            return totalSale ? totalSale.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.value}</b>;
            return params.value;
        }
    }
];

const R_COLUMNS = [
    {
        field: 'title', headerName: 'Title', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.category ? params.row.category.toUpperCase() : ""}</b>;
        }
    },
    {
        field: 'category', headerName: 'Category', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.category ? params.row.category.toUpperCase() : "";
        }
    },
    {
        field: 'price', headerName: 'Remaining', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            if (params.row.sold && params.row.sold != 0 && params.row.price && params.row.downPaymentAmount) {
                let remainingAmount = params.row.price - params.row.downPaymentAmount;
                return remainingAmount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
            } else {
                return "-";
            }
        }
    },
    {
        field: 'original-stock', headerName: 'Total Stock', width: 100, filterable: false, valueGetter: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock + params.row.sold;
        }
    },

    {
        field: 'sold', headerName: 'Sold', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.sold}</b>;
            return params.row.sold ? params.row.sold : 0;
        }
    },
    { field: 'stock', headerName: 'Remaining Stock', width: 100, filterable: false },
    {
        field: 'totalRemaining', headerName: 'Total Remaining', width: 100, filterable: false, valueGetter: (params) => {

            let remainingAmount = params.row.price - params.row.downPaymentAmount;
            let totalRmaining = remainingAmount * params.row.sold;
            if (params.row.id === 'total') totalRmaining = params.row.totalRmainingAmount;

            return totalRmaining ? totalRmaining.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";

        }, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.value}</b>;
            return params.value;
        }
    }
];

const C_COLUMNS = [
    {
        field: 'code', headerName: 'Coupon Code', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.code.toUpperCase()}</b>;
            return params.row.code.toUpperCase();
        }
    },
    {
        field: 'discount', headerName: 'Discount (TL)', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.discount ? params.row.discount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }
    },
    { field: 'stock', headerName: 'Stock', width: 100, filterable: false },
    {
        field: 'used', headerName: 'Used', width: 100, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return <b>{p.row.totalUsed}</b>;
            return p.row.used ? p.row.used : 0;

        }
    },
    {
        field: 'remaining', headerName: 'Remaining', width: 100, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return null;
            let remaining = p.row.stock - (p.row.used ? p.row.used : 0)
            if (remaining < 0) remaining = 0;
            return remaining;
        }
    },
    {
        field: 'totalDiscount', headerName: 'Total Discount (TL)', width: 150, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return p.row.totalDiscount ? <b>{p.row.totalDiscount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b> : "-";
            let totalDisc = p.row.discount * (p.row.used ? p.row.used : 0);
            if (totalDisc == 0) return "-";
            else return totalDisc.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
        }
    }
];

const CustomTableHeader = () => {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
            {/* <GridToolbarExport csvOptions={{ disableToolbarButton: true }} printOptions={{ hideToolbar: true }} excelOptions={{ fileName: `gisekibris-sales-${moment().format('LL-LT')}.xlsx` }} /> */}
        </GridToolbarContainer>
    );
}
const EventStockReport = () => {

    let hasEnteredFetching = false;

    const event = useRecordContext();
    const notify = useNotify();
    const { permissions } = usePermissions();

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState(false);
    const [columns, setColumns] = useState(D_COLUMNS);

    const [couponsData, setCouponsData] = useState(false);
    const [couponColumns, setCouponColumns] = useState(C_COLUMNS);

    const [remainingData, setRemainingData] = useState(false);
    const [remainingColumns, setRemainingColumns] = useState(R_COLUMNS);

    const [check, setCheck] = useState(false);

    // summary data
    const [totalProductSalesSummary, setTotalProductSalesSummary] = useState(0);
    const [totalCommssionAmountSummary, setTotalCommssionAmountSummary] = useState(0);
    const [totalRmainingAmountSummary, setTotalRmainingAmountSummary] = useState(0);
    const [totalCouponDiscountSummary, setTotalCouponDiscountSummary] = useState(0);
    const [subTotal, setSubTotal] = useState(0);

    // summary data from sales
    const [salesData, setSalesData] = useState(false);
    const [actualSalesData, setActualSalesData] = useState({
        totalSaleAmount: 0,
        totalSuccessfullSalesCount: 0,
        totalAmountOfCashSales: 0,
        totalAmountOfCCSales: 0,
        totalSoldTickets: 0,
    });

    // payment data
    const [isApproved, setIsApproved] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    let productCategories = ['deal', 'general', 'promotion', 'vip', 'invitation', 'extras', 'guestlist', 'reservation'];

    let isAdmin = false;
    if (permissions.crole && permissions.crole === "admin") isAdmin = true;

    useEffect(() => {

        (
            async () => {
                // by actual sales

                if(!salesData) {
                    await fetchEventSalesData();
                    return;
                }

                // by event data
                if(!data) {
                    await computeSalesData();
                    return;
                }

                if(!remainingData) {
                    await computeRemainingPayments();
                    return;
                }

                if(!couponsData) {
                    await computeCouponsData();
                    return;
                }

                await computeCommission();

                // set payment data
                setIsApproved(event.isApproved);
                setIsPaid(event.isPaid);
            }
        )()

    }, [salesData, data, remainingData, couponsData]);

    const fetchEventSalesData = async () => {

        if (!event.id) return;

        if (hasEnteredFetching) return;
        hasEnteredFetching = true;

        console.log('fetching sales data');

        const q = query(
            collection(db, "sales"),
            where("event.id", "==", event.id),
            where("status", '==', 1)
        );

        const querySnapshot = await getDocs(q);

        let salesEventS = [];
        let totalSaleAmount = 0;
        let totalSuccessfullSalesCount = 0;
        let totalAmountOfCashSales = 0;
        let totalAmountOfCCSales = 0;
        let totalSoldTickets = 0;

        querySnapshot.forEach((doc) => {
            let data = doc.data();

            salesEventS.push(data);

            totalSaleAmount += data.paymentTotal;
            totalSuccessfullSalesCount++;

            if (data.tickets.length && parseInt(data.tickets.length)) {
                totalSoldTickets += data.tickets.length;
            }

            // account for both cash and doorcash
            if (data.paymentType === 'cash' || data.paymentType === 'doorcash') {
                totalAmountOfCashSales += data.paymentTotal;
            } else {
                totalAmountOfCCSales += data.paymentTotal;
            }
        });

        console.log('setting sales data');
        setSalesData(true);
        return setActualSalesData({
            totalSaleAmount: totalSaleAmount,
            totalSuccessfullSalesCount: totalSuccessfullSalesCount,
            totalAmountOfCashSales: totalAmountOfCashSales,
            totalAmountOfCCSales: totalAmountOfCCSales,
            totalSoldTickets: totalSoldTickets
        });
    }

    const computeSalesData = async () => {
        let tData = [];

        productCategories.forEach((productCategory) => {
            if (event.tickets[productCategory]) {
                event.tickets[productCategory].forEach((product) => {

                    let productData = {
                        id: product.id,
                        category: productCategory,
                        title: product.title.en,
                        price: product.price,
                        stock: product.stock,
                        sold: product.sold
                    }

                    tData.push(productData);
                });
            }
        });


        // We need a total row at the end, only for sold and total sale
        let totalSold = 0;
        let totalSale = 0;
        tData.forEach((product) => {
            totalSold += product.sold;
            totalSale += product.sold * product.price;
        });

        let totalRow = {
            id: 'total',
            category: 'Total',
            title: '',
            price: '',
            stock: '',
            sold: totalSold,
            totalSale: totalSale
        }

        tData.push(totalRow);

        setData(tData);
        setTotalProductSalesSummary(totalSale);
    }

    const computeRemainingPayments = async () => {
        let remainingPayments = [];

        productCategories.forEach((productCategory) => {
            if (event.tickets[productCategory]) {
                event.tickets[productCategory].forEach((product) => {
                    if (product.hasDownPayment) {
                        let productData = {
                            id: product.id,
                            category: productCategory,
                            title: product.title.en,
                            price: product.price,
                            stock: product.stock,
                            sold: product.sold,
                            downPaymentAmount: product.downPaymentAmount
                        }

                        remainingPayments.push(productData);
                    }
                });
            }
        });

        // We need a total row at the end, only for sold and total sale
        let totalSold = 0;
        let totalRmainingAmount = 0;
        remainingPayments.forEach((product) => {
            totalSold += product.sold;
            let remainingAmount = product.price - product.downPaymentAmount;
            totalRmainingAmount += product.sold * remainingAmount;
        });

        let totalRow = {
            id: 'total',
            category: 'Total',
            title: '',
            price: '',
            stock: '',
            sold: totalSold,
            totalRmainingAmount: totalRmainingAmount
        }

        remainingPayments.push(totalRow);
        setRemainingData(remainingPayments);
        setTotalRmainingAmountSummary(totalRmainingAmount);
    }

    const computeCouponsData = async () => {
        let couponsData = [];

        if (event.coupons) {
            event.coupons.forEach((coupon) => {
                let couponData = {
                    id: coupon.code,
                    code: coupon.code,
                    discount: coupon.discount,
                    stock: coupon.stock,
                    used: coupon.used
                }

                couponsData.push(couponData);
            });


            // We need a total row at the end, only for sold and total sale
            let totalDiscount = 0;
            let totalUsed = 0;
            couponsData.forEach((coupon) => {
                if (coupon.used) {
                    totalDiscount += (coupon.discount * coupon.used);
                    totalUsed += coupon.used ? coupon.used : 0;
                }
            });

            let totalRow = {
                id: 'total', code: 'Total', stock: '', used: '', remaining: '', discount: '', totalDiscount: totalDiscount, totalUsed: totalUsed
            }

            couponsData.push(totalRow);

            setCouponsData(couponsData);
            setTotalCouponDiscountSummary(totalDiscount);
        }
    }

    const computeCommission = async () => {

        // first calculate commission
        const totalStockSale = totalProductSalesSummary;
        const totalActualSale = actualSalesData.totalSaleAmount;
        const totalOrganisatorCashSales = actualSalesData.totalAmountOfCashSales;
        const coupons = totalCouponDiscountSummary;
        const remaining = totalRmainingAmountSummary;

        let sub_total = totalStockSale - coupons - remaining - totalOrganisatorCashSales;
        let commission = sub_total * (event.commissionFee / 100);

        console.log('\n------------------');
        console.log('totalActualSale', totalActualSale); // actual that got sold
        console.log('totalActualCCSales', actualSalesData.totalAmountOfCCSales); // actual that got sold (cc sales
        console.log('totalActualCashSales', actualSalesData.totalAmountOfCashSales); // actual that got sold (cash sales)
        console.log('totalNumberOfCreatedTickets', actualSalesData.totalSoldTickets); // actual that got created
        
        console.log('\n')
        console.log('totalStockSale: ', totalStockSale);
        console.log('coupons', coupons);
        console.log('remaining', remaining);
        // console.log('org. cash sales', totalOrganisatorCashSales);
        console.log('\n')

        console.log(`commission ${event.isCommissionExtra ? 'extra' : 'inc.'}`, commission);

        let checkCalc = (
            (
                sub_total + totalOrganisatorCashSales // because we have this in our totalActualSale
            )
            ===
            (
                totalActualSale - (event.isCommissionExtra ? commission : 0)
            )
        );

        setTotalCommssionAmountSummary(commission);
        setSubTotal(sub_total);

        console.log('Sub Total (+ Cash Sales) === totalActualSale: ', sub_total + totalOrganisatorCashSales, totalActualSale - (event.isCommissionExtra ? commission : 0));
        console.log('Sub Total (No Cash Sales) === totalCCSales: ', sub_total, actualSalesData.totalAmountOfCCSales - (event.isCommissionExtra ? commission : 0));
        
        // whoever is bigger is the diff, we need to check the diff
        let a = sub_total + totalOrganisatorCashSales;
        let b = totalActualSale - (event.isCommissionExtra ? commission : 0);
        if (a > b) {
            console.log('Diff: ', a - b);
        }
        else {
            console.log('Diff: ', b - a);
        }

        if (checkCalc) setCheck(true);
        else setCheck(false);

        setIsLoading(false);
    }

    const printCheck = () => {
        if (check) {
            return <span style={{ color: 'green' }}>&#10004;</span>;
        }
        else {
            return <div><span style={{ color: 'red' }}>&#10006;</span></div>;
        }
    }

    const onPressApprove = () => {

        if (!check) {
            notify('There is an issue with this event\'s payment record. Please contact Gişe Kıbrıs to proceed with payment.', {
                type: 'error'
            });
            return;
        }

        if (isAdmin) {
            notify('Admin can not approve payment for an event.', {
                type: 'error'
            });
            return;
        }

        if (isApproved === true) {
            notify('Event payment has already been approved.', {
                type: 'info'
            });
            return;
        }

        // we will set the field isApproved to true for the "event" object.
        if (window.confirm('Are you sure you want to approve the payment for this event?')) {
            updateDoc(doc(db, "events", event.id), {
                isApproved: true
            }).then(() => {
                setIsApproved(true);
                notify('Event payment has been approved', {
                    type: 'success'
                });
            }).catch((error) => {
                // console.error('Error approving event: ', error);
                notify('Error approving payment for the event, please contact Gişe Kıbrıs.', {
                    type: 'error'
                });
            });
        }
    }

    const onPressMakePayment = () => {
        // we will set the field isPaid to true for the "event" object.
        if (isPaid === true) {
            notify('Event payment has already been made.', 'info');
            return;
        }

        let confirmMSG = 'Are you sure you want to make the payment for this event?';
        if (isApproved === false) confirmMSG = 'The payment for this event has not been approved yet. Do you want to proceed with the payment?';

        if (window.confirm(confirmMSG)) {
            updateDoc(doc(db, "events", event.id), {
                isPaid: true
            }).then(() => {
                setIsPaid(true);
                notify('Event payment has been made.', {
                    type: 'success'
                });
            }).catch((error) => {
                // console.error('Error making payment for event: ', error);
                notify('Error making payment for the event, please contact Gişe Kıbrıs.', {
                    type: 'error'
                });
            });
        }
    }


    if (isLoading) return <div>Loading...</div>;

    return (
        <div style={{ paddingLeft: 30, paddingRight: 30, fontSize: 16 }}>
            <div style={{ textAlign: 'center', marginBottom: 50 }}>
                <h2>{event.name} - {moment(event.startdate).format("LL")}</h2>
            </div>

            {/* Product Sales */}
            <div>
                <h3>Sales</h3>
                <DataGridPremium
                    rows={data}
                    density="compact"
                    initialState={{
                        columns: { columnVisibilityModel: {} },
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    onColumnVisibilityModelChange={(model) => {
                        setColumns(
                            columns.map((column) => ({
                                ...column,
                                hide: !model[column.field],
                            }))
                        );
                    }}
                    style={{ flex: 1, width: '100%' }}
                    getRowId={(row) => row.id}
                    columns={columns}
                    hideFooter={true}
                    slots={{
                        toolbar: CustomTableHeader
                    }}
                />
            </div>


            {/* REMAINING PAYMENTS (FOR DOWN PAYMENT ENABLED PRODUCTS) */}
            <div style={{ marginTop: 50, marginBottom: 50 }}>
                <h3>Remaining Payments</h3>
                <DataGridPremium
                    rows={remainingData}
                    density="compact"
                    initialState={{
                        columns: { columnVisibilityModel: {} },
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    onColumnVisibilityModelChange={(model) => {
                        setRemainingColumns(
                            remainingColumns.map((column) => ({
                                ...column,
                                hide: !model[column.field],
                            }))
                        );
                    }}
                    style={{ flex: 1, width: '100%' }}
                    getRowId={(row) => row.id}
                    columns={remainingColumns}
                    hideFooter={true}
                    slots={{
                        toolbar: CustomTableHeader
                    }}
                />
            </div>

            {/* COUPONS */}
            <div>
                <h3>Coupons</h3>
                <DataGridPremium
                    rows={couponsData}
                    density="compact"
                    initialState={{
                        columns: { columnVisibilityModel: {} },
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    onColumnVisibilityModelChange={(model) => {
                        setCouponColumns(
                            couponColumns.map((column) => ({
                                ...column,
                                hide: !model[column.field],
                            }))
                        );
                    }}
                    style={{ flex: 1, width: '100%' }}
                    getRowId={(row) => row.id}
                    columns={couponColumns}
                    hideFooter={true}
                    slots={{
                        toolbar: CustomTableHeader
                    }}
                />
            </div>

            {/* SUMMARY SECTION */}
            <div>

                {/* Right Aligned */}

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50, marginBottom: 50, marginRight: 50 }}>

                    <div>
                        <div style={{ width: 500, fontSize: 16 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Product Sales (Stock)</div>
                                <div>{totalProductSalesSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Remaining Amount</div>
                                <div>{totalRmainingAmountSummary.toLocaleString('tr-TR', { style: 'currency', 'currency': 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Coupon Discount</div>
                                <div>{totalCouponDiscountSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Organisator Sales (Cash)</div>
                                <div>{actualSalesData.totalAmountOfCashSales.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Sub Total</div>
                                <div><b>{(subTotal).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b></div>
                            </div>

                        </div>

                        <div style={{ width: 500, fontSize: 16, marginTop: 30 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Gişe Kıbrıs Fee (<b>{event.isCommissionExtra ? 'extra' : 'included'}</b> {event.commissionFee}%)</div>
                                <div>{totalCommssionAmountSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Payable</div>
                                <div>
                                    <b>{((totalProductSalesSummary - (event.isCommissionExtra ? 0 : totalCommssionAmountSummary) - (totalCouponDiscountSummary + totalRmainingAmountSummary)) - actualSalesData.totalAmountOfCashSales).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    Check
                                </div>
                                <div>
                                    {printCheck()}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/* Action buttons */}
            {/* Approve and Make Payment */}

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50, marginBottom: 50, marginRight: 50 }}>
                <button onClick={onPressApprove} style={{ padding: 5, cursor: 'pointer', backgroundColor: isApproved ? '#AAA' : 'rgb(156,39,176)', color: 'white', borderRadius: 5, fontSize: 16, borderColor: 'transparent' }}>Approve Payment</button>

                {/* {
                    isAdmin && (
                        <button onClick={onPressMakePayment} style={{ padding: 5, cursor: 'pointer', backgroundColor: 'rgb(156,39,176)', color: 'white', borderRadius: 5, fontSize: 16, marginLeft: 10, borderColor: 'transparent' }}>Make Payment</button>
                    )
                } */}
            </div>

        </div>
    )
}

export default EventStockReport;