import React from 'react';
import { createRoot } from 'react-dom/client';
import { Route } from "react-router-dom";

import { Admin, Resource, ShowGuesser, CustomRoutes } from 'react-admin';

// Styles
import './styles/index.css';

// Data Provider
import { FirebaseDataProvider, FirebaseAuthProvider } from 'react-admin-firebase';

// Config
import { FirebaseConfig, FirebaseOptions } from './config/firebase';

// Resources
// import { SaleList } from './resources/sales';
// import { TicketList } from './resources/tickets';
import { PaymentShow } from './resources/payments';

import { EventCreate,EventEdit, EventShow } from './resources/events';
import { EventList } from './resources/events/list';
import { VenueCreate, VenueList, VenueEdit } from './resources/venues';
import { UserList, UserEdit } from './resources/users';
import { CityCreate, CityList, CityEdit } from './resources/cities';
import { OrganisationCompanyCreate, OrganisationCompanyList, OrganisationCompanyEdit } from './resources/organisationCompany';
import { SalePointCreate, SalePointList, SalePointEdit } from './resources/salePoints';
import { AffiliatesCreate, AffiliatesList, AffiliatesEdit } from './resources/affiliates';
import { SaleAgentCreate, SaleAgentList, SaleAgentEdit } from './resources/saleAgents';
import { PromoterCreate, PromoterEdit, PromoterList } from './resources/promoters';
import { GroupEventCreate, GroupEventList, GroupEventEdit } from './resources/groupEvents';

import { FeaturedList, FeaturedEdit } from './resources/featureds';
import { CategoryCreate, CategoryList, CategoryEdit } from './resources/categories';
import { SubCategoryCreate, SubCategoryList, SubCategoryEdit } from './resources/subcategories';
import { VenueCategoryCreate, VenueCategoryList, VenueCategoryEdit } from './resources/venueCategories';
import { EventTagsCreate, EventTagsList, EventTagsEdit } from './resources/eventTags';
import { LayoutCreate, LayoutList, LayoutEdit } from './resources/layouts';
import { SystemParameterList, SystemParameterEdit } from './resources/systemParameters';

import { HomeSectionCreate, HomeSectionList, HomeSectionEdit } from './resources/homeSections';
import { HeroCreate, HeroList, HeroEdit } from './resources/heros';
import { PageCreate, PageList, PageEdit } from './resources/pages';
import { NewsCreate, NewsEdit, NewsList } from './resources/news';
import { DeleteUserRequestList } from './resources/deleteUserRequests';

import { AffiliateFormEdit, AffiliateFormList } from './resources/affiliateForm';
import { SessionEventCreate, SessionEventEdit, SessionEventList, SessionEventShow } from './resources/sessionEvents';

import { LogList } from './resources/verificationLogs';
import { SaleLogList } from './resources/saleLogs';
import { EventLogList } from './resources/eventLogs';

// Custom pages
import Dashboard from './pages/dashboard';
import SalesPage from './pages/sales';
import TicketsPage from './pages/tickets';
import UsersPage from './pages/users';

// Components
import CustomLayout from './components/layout/CustomLayout';

// Theme
import theme from './constants/theme';

// Data Provider
const dataProvider = FirebaseDataProvider(FirebaseConfig, FirebaseOptions);
const authProvider = FirebaseAuthProvider(FirebaseConfig, FirebaseOptions);

const customAuthProvider = {
  ...authProvider,
  login: async (params) => {
    const user = await authProvider.login(params);
    const claims = await authProvider.getPermissions();
    const roles = ['admin', 'organisationCompanyManager', 'venueManager', 'affiliate'];
    const canEnter = claims.crole && roles.includes(claims.crole);
    if (canEnter) return user;
    else {
      await authProvider.logout()
      throw new Error("Login error, invalid permissions");
    }
  },
};

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Admin theme={theme} layout={CustomLayout}
      authProvider={customAuthProvider} dataProvider={dataProvider} title="Gişe Kıbrıs" requireAuth>

      <Resource name="events" list={EventList} edit={EventEdit} create={EventCreate} show={EventShow} />

      <Resource name="dashboard" list={Dashboard} />

      <Resource name="sales" list={SalesPage} />
      <Resource name="tickets" list={TicketsPage} />
      <Resource name="payments" show={PaymentShow} />

      <Resource name="users" list={UsersPage} edit={UserEdit} />

      <Resource name="sessionEvents" list={SessionEventList} edit={SessionEventEdit} create={SessionEventCreate} show={SessionEventShow} />
      <Resource name="venues" list={VenueList} create={VenueCreate} edit={VenueEdit} />
      <Resource name="layouts" list={LayoutList} create={LayoutCreate} edit={LayoutEdit} />
      <Resource name="cities" list={CityList} create={CityCreate} edit={CityEdit} />
      <Resource name="organisationCompanies" list={OrganisationCompanyList} create={OrganisationCompanyCreate} edit={OrganisationCompanyEdit} />
      <Resource name="salePoints" list={SalePointList} create={SalePointCreate} edit={SalePointEdit} />
      <Resource name="saleAgents" list={SaleAgentList} create={SaleAgentCreate} edit={SaleAgentEdit} />
      <Resource name="promoters" list={PromoterList} create={PromoterCreate} edit={PromoterEdit} />
      <Resource name="affiliates" list={AffiliatesList} create={AffiliatesCreate} edit={AffiliatesEdit} />
      <Resource name="groupEvents" list={GroupEventList} create={GroupEventCreate} edit={GroupEventEdit} />
      <Resource name="affiliateForm" list={AffiliateFormList} edit={AffiliateFormEdit} />

      <Resource name="featureds" list={FeaturedList} edit={FeaturedEdit} />
      <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} />
      <Resource name="subcategories" list={SubCategoryList} create={SubCategoryCreate} edit={SubCategoryEdit} />
      <Resource name="venueCategories" list={VenueCategoryList} create={VenueCategoryCreate} edit={VenueCategoryEdit} />
      <Resource name="eventTags" list={EventTagsList} create={EventTagsCreate} edit={EventTagsEdit} />

      <Resource name="deleteUserRequests" list={DeleteUserRequestList} />

      <Resource name="systemParameters" list={SystemParameterList} edit={SystemParameterEdit} />

      <Resource name="homeSections" list={HomeSectionList} create={HomeSectionCreate} edit={HomeSectionEdit} />
      <Resource name="heros" list={HeroList} create={HeroCreate} edit={HeroEdit} />
      <Resource name="pages" list={PageList} create={PageCreate} edit={PageEdit} />
      <Resource name="news" list={NewsList} create={NewsCreate} edit={NewsEdit} />

      <Resource name="eventLogs" list={EventLogList} />
      <Resource name="verificationLogs" list={LogList} />
      <Resource name="paymentLogs" list={SaleLogList} />

    </Admin>
  </React.StrictMode>
);