import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, FunctionField, EditButton,
    ReferenceInput, ReferenceField, AutocompleteInput, TextInput,
    FormDataConsumer, SelectArrayInput, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import moment from "moment";
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';
import _ from 'lodash';

// Entity
const entity = "promoters";

const exporter = async (records, _getMany, dataProvider) => {

    // gather relationship ids
    const userIds = records.map((record) => record.user ?? null);
    const u_userIds = _.uniq(userIds);

    const users = await dataProvider.getMany('users', { ids: u_userIds });
    const data = [];

    for (const record of records) {
        const userIndex = users.data && users.data.length > 0 ? users.data.findIndex(v => v.id === record.user) : -1;
        data.push({
            "Promoter ID": record.id,
            "System User Name": userIndex > -1 ? `${users.data[userIndex].name} ${users.data[userIndex].surname}` : '-',
            "System User Email": userIndex > -1 ? `${users.data[userIndex].email}` : '-',
        });
    }

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-organisation-companies-${moment().format('LL-LT')}.xlsx`);
    });
}

export const PromoterList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Promoters" exporter={exporter}>
        <Datagrid>
            <ReferenceField source="user" reference="users" label="User" linkType="show">
                <FunctionField render={(r) => `${r.name} ${r.surname}`} />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

export const PromoterEdit = (props) => (
    <Edit title="Edit" {...props} redirect={"list"}>
        <SimpleForm redirect={`/${entity}`}>
            <ReferenceInput source="user" reference="users">
                <AutocompleteInput optionText={(r) => r.email + (r.crole ? ` (${r.crole})` : '')} fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData }) => (
                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={null}>
                        <SelectArrayInput optionText={"name"} fullWidth defaultValue={null} disabled={formData && formData.venues && formData.venues.length !== 0} />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => (
                    <ReferenceArrayInput source="venues" reference="venues" label={"Venue"} defaultValue={null}>
                        <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} disabled={formData && formData.organisationCompanies && formData.organisationCompanies.length !== 0} clearOnBlur={false}  />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} defaultValue={null} />
        </SimpleForm>
    </Edit>
);

export const PromoterCreate = (props) => (
    <Create title="New" {...props} redirect={"list"}>
        <SimpleForm redirect={`/${entity}`}>
            <ReferenceInput source="user" reference="users">
                <AutocompleteInput optionText={(r) => r.email + (r.crole ? ` (${r.crole})` : '')} fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData }) => (
                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={null}>
                        <SelectArrayInput optionText={"name"} fullWidth defaultValue={null} disabled={formData && formData.venues && formData.venues.length !== 0} />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => (
                    <ReferenceArrayInput source="venues" reference="venues" label={"Venue"} defaultValue={null}>
                        <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} disabled={formData && formData.organisationCompanies && formData.organisationCompanies.length !== 0} clearOnBlur={false}  />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} defaultValue={null} />
        </SimpleForm>
    </Create>
);
